import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../App';

const LastPage = () => {
  const location = useLocation();
  const { company } = useContext(UserContext);

  const totalPathToIndex = {
    "/": 0,
    "/direction": 0,
    "/tax-assistant/associates": 1,
    "/tax-assistant/activity-type": 2,
    "/tax-assistant/remunerated": 3,
    "/tax-assistant/ca-estimation": 4,
    "/tax-assistant/charges": 5,
    "/tax-assistant/result": 6,
    "/form/legal-statuts": 7,
    "/form/legal-statuts/SASU": 7,
    "/form/contact-informations": 8,
    "/form/self-employed": 9, // fastlane
    "/form/already-set_up-business": 10,// fastlane
    "/form/work-as": 11, // fastlane
    "/form/dealname": 12,
    "/form/register-your-trademark": 13, // fastlane
    "/form/activity": 14, // fastlane
    "/form/craft-activity": 15, // fastlane
    "/form/domiciliation": 16, // fastlane
    "/form/bank": 17, // fastlane
    "/form/accounting": 18,
    "/form/recap": 19,
    "/form/legal-pack": 20,
    "/form/merchant-recap": 21,
    "/form/checkout": 22,
    "/form/payment-success": 23,
    "/form/payment-success/alma": 23,
    "/form/payment-success/paypal": 23,
    "/form/payment-success/sepa": 23,
    "/form/contract": 24,
    "/form/success": 25,
    "dynamic": (path) => {
      // Gérer les cas de chemins dynamiques avec ID
      const dynamicRegex = /^\/\d+$/;
      if (dynamicRegex.test(path)) {
        return 0; // L'indice pour les URLs avec un ID
      }
      return -1; // Un indice qui indique une non-correspondance
    }
  }

  const getPathIndex = (path) => {
    if (totalPathToIndex[path] !== undefined) {
      return totalPathToIndex[path];
    } else if (typeof totalPathToIndex['dynamic'] === 'function') {
      return totalPathToIndex['dynamic'](path);
    }
    return -1; // Fallback si aucune correspondance n'est trouvée
  }

  return (
    (company.form?.last_page && company.form?.last_page !== "/" && getPathIndex(location.pathname) === 0) ? (
      <div className='prev_url'>
        <div className='prev_paragraphe'>
          <span className='bold'>Heureux de vous revoir !</span> Reprenez la création de votre future {company.form?.legal_statuts !== '' ? company.form?.legal_statuts : 'entreprise'}
        </div>
        <Link to={company.form?.last_page}>
          <div className='prev_button'>Reprendre mon formulaire</div>
        </Link>
      </div>
    ) : (
      <></>
    )
  );
}

export default LastPage;