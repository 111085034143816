import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './already-set_up-business.module.css';

function AlreadySetUpBusiness() {
    const { handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button, .later');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            navigate('/form/work-as');
        }
    }

    const details = '🌟 Comptastar peut s\'occuper de la radiation de votre ancienne entreprise en quelques jours seulement.\nGarantie anti rejet, cela vous permettra de passer à autre chose avec l\'esprit tranquille.';
    return (
        <div className="self-step main-10">
            <div className="global">
                <div className="questions">Avez-vous <span className="important">déjà créé une entreprise</span> ?</div>
                <div className="answers" question='form.already-set_up-business'>
                    <div className={`multiform ${styles.multiform}`} question='form.already-set_up-business'>
                        <div className="big-button" onClick={handleSubmit} choice='self-employed'>
                            <div className="emoji">🙋</div>
                            <div className="affirmation">J'ai déjà créé une auto-entreprise.</div>
                            <div className="description"></div>
                            <div className="subtitle">Et oui, ce n'est pas la première fois !</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice='already_set_up'>
                            <div className="emoji">💁</div>
                            <div className="affirmation">J'ai déjà créé une société.</div>
                            <div className="description"></div>
                            <div className="subtitle">Je ne suis pas un débutant !</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice='never'>
                            <div className="emoji">🙅</div>
                            <div className="affirmation">Je n'ai jamais créé d'entreprise.</div>
                            <div className="description"></div>
                            <div className="subtitle">C'est pour ça que je viens chez vous !</div>
                        </div>
                    </div>
                    <div className={`later ${styles.later}`} onClick={handleSubmit} choice=''>Je souhaite répondre plus tard.</div>
                </div>
                <div className="main-details">
                    <div className="details">{details}</div>
                </div>
                <div className="actions">
                    <Link to='/form/contact-informations'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link to='/form/work-as'><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}

export default AlreadySetUpBusiness;