import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Remunerated() {
    const { handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            if(choice === 'pay_slip') {
                navigate('/tax-assistant/result');
            } else {
                navigate('/tax-assistant/ca-estimation');
            }
        }
    }
    const details = '🌟 En général, nous observons que 85% des créateurs deviennent Président et Salarié. \nNous pourrons le modifier par la suite gratuitement grâce à votre juriste dédié.';
    return (
        <div className="self-step main-4">
            <div className="global">
                <div className="questions">C'est noté ! Comment souhaitez-vous être <span className="important">rémunéré</span> ?</div>
                <div className="answers">
                    <div className="multiform" question='assistant.remunerated'>
                        <div className="big-button" onClick={handleSubmit} choice="pay_slip">
                            <div className="emoji">👨‍💼</div>
                            <div className="affirmation">Je serai Président et donc salarié.</div>
                            <div className="description"></div>
                            <div className="subtitle">Important d'être protégé en tant que dirigeant.</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="immediate">
                            <div className="emoji">💰</div>
                            <div className="affirmation">J'ai un besoin immédiat de rémunération.</div>
                            <div className="description"></div>
                            <div className="subtitle">Nos montages de sociétés sont très utiles pour cela.</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="dividends">
                            <div className="emoji">🪙</div>
                            <div className="affirmation">Je me verserai des dividendes.</div>
                            <div className="description"></div>
                            <div className="subtitle">Vous éviterez donc de payer des charges sociales.</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="complementary_salaried_employment">
                            <div className="emoji">💸</div>
                            <div className="affirmation">Ce sera un complément à mon activité de salarié.</div>
                            <div className="description"></div>
                            <div className="subtitle">Toujours important de diversifier vos sources de revenus.</div>
                        </div>
                    </div>
                </div>
                <div className='main-details'>
                    <div className="details" style={{whiteSpace: 'pre-wrap'}}>{details}</div>
                </div>
                <div className="actions">
                    <Link to='/tax-assistant/activity-type'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link to='/tax-assistant/ca-estimation'><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}

export default Remunerated;