import styles from './progressbar.module.css';
import Projet from '../icon/projet';
import Services from '../icon/services';
import Commande from '../icon/commande';
import Validation from '../icon/validation';

function ProgressBar({currentStep}) {
    const steps = [{"label": "Projet", "icon": <Projet />}, {"label": "Services", "icon": <Services />}, {label: "Commande", "icon": <Commande />}, {"label": "Validation", "icon": <Validation/>}];
    return (
        <div className={styles['progress-bar']}>
            <div className={styles.sub}>
                {steps.map((step, index) => (
                    <div key={step.label} className={`${styles.step} ${index <= currentStep ? styles.active : ""} ${index < currentStep ? styles.notLastActive : ""}`}>
                        <div className={styles.icon}>{step.icon}</div>
                        <div className={styles.label}>{step.label}</div>
                        {step.label !== 'Validation' && (
                            <div className={styles.divider}></div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProgressBar;