import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';

function Associates() {
    const { handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            navigate('/tax-assistant/activity-type')
        }
    }

    return (
        <div className="self-step main-2">
            <div className="global">
                <div className="questions">Parfait, vous créez votre société <span className="important">seul ou à plusieurs</span> ?</div>
                <div className="answers">
                    <div className="multiform" question="assistant.solo_associate">
                        <div className="big-button" onClick={handleSubmit} choice="true">
                            <div className="emoji">☝️</div>
                            <div className="affirmation">Je me lance seul.</div>
                            <div className="description"></div>
                            <div className="subtitle">Pas besoin d'associé pour démarrer.</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="false">
                            <div className="emoji">✋</div>
                            <div className="affirmation">On se lance à plusieurs.</div>
                            <div className="description"></div>
                            <div className="subtitle">Comme les entreprises Apple et Google.</div>
                        </div>
                    </div>
                </div>
                <div className='main-details'>
                    <div className="details">
                        🌟 Nos juristes peuvent ainsi déterminer vos statuts <span className="bold">sur-mesure et les plus adaptés à votre situation.</span> Rassurez-vous, cela pourra être modifié en fonction de l'évolution de votre entreprise !
                    </div>
                </div>
                <div className="actions">
                    <Link to='/'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link to='/tax-assistant/activity-type'><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}

export default Associates;