import styles from './contact-informations.module.css'
import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../../App';
// import GoogleSignIn from '../../google-sign_in/google-sign_in';
import { Link, useNavigate } from 'react-router-dom';
import dataLayerPush from '../../../utils/data-layer';

function ContactInformations() {
    const { company, setCompany, handleChange } = useContext(UserContext);
    const navigate = useNavigate();
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const connectionForm = useRef(null);
    const [creation_count, set_creation_count] = useState(() => {
        return 0;
    });
    const [warning, setWarning] = useState([]);
    const validationComplete = useRef(false);
    const [timestamp, setTimestamp] = useState(Date.now());

    useEffect(() => {
        const inputs = [firstNameRef, lastNameRef, emailRef, phoneRef];
        inputs.forEach(ref => {
            if (ref.current && ref.current.checkValidity()) {
                ref.current.classList.add(styles.active);
            } else {
                ref.current.classList.remove(styles.active);
            }
        });
        validationComplete.current = false;
    }, []);

    function handleUserAuthenticated({ firstname, lastname, email }) {
        const updatedCompany = {
            ...company,
            form: {
                ...company.form,
                contact: {
                    ...company.form.contact,
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    phone: company.form.contact.phone
                }
            }
        };
        setCompany(updatedCompany);
    }

    // Appliquer les classes directement sans générer d'événements factices
    function applyClassesDirectly() {
        const fields = [firstNameRef, lastNameRef, emailRef, phoneRef];
        fields.forEach(ref => {
            if (ref.current) {
                if (ref.current.value && ref.current.checkValidity()) {
                    ref.current.classList.add(styles.active);
                } else {
                    ref.current.classList.remove(styles.active);
                }
            }
        });
    }

    // Utiliser useEffect pour surveiller les changements spécifiques si nécessaire
    useEffect(() => {
        applyClassesDirectly(); // Appelé après chaque rendu pour réappliquer les classes basées sur les valeurs actuelles
        switch (company.form.legal_statuts) {
            case 'SAS': case 'SASU':
                set_creation_count('1 956');
                break;
            case 'SARL': case 'EURL': case 'EIRL':
                set_creation_count('1 560');
                break;
            case 'Holding':
                set_creation_count('1 160');
                break;
            case 'Auto-entrepreneur':
                set_creation_count('9 345');
                break;
            case 'Entreprise individuelle':
                set_creation_count('5 278');
                break;
            case 'Association':
                set_creation_count('1 223');
                break;
            default:
                set_creation_count('1 223');
                break;
        }
    }, [company.form]);

    function handleAllChange(event) {
        handleChange(event);
        if(event.target.classList) {
            if(event.target.checkValidity()) {
                event.target.classList.add(styles.active);
            } else {
                event.target.classList.remove(styles.active);
            }
        }
    }

    const handleClickForDataLayer = () => {
        dataLayerPush({
        event: 'Clickbutton',
        eventLabel: 'Step 4',
        });
    };

    function handleClickForm() {
        console.log(validationComplete.current);
        const removeItemOnce = (value) => {
            setWarning(prevArray => {
            const index = prevArray.indexOf(value);
            if (index > -1) {
                return [...prevArray.slice(0, index), ...prevArray.slice(index + 1)];
            }
            return prevArray;
            });
        };

        const addItemIfNotPresent = (value) => {
            setWarning(prevArray => {
              if (!prevArray.includes(value)) {
                return [...prevArray, value];
              }
              return prevArray;
            });
        };
        const inputs = [firstNameRef, lastNameRef, emailRef, phoneRef];
        inputs.forEach(ref => {
            if (ref.current && ref.current.checkValidity()) {
                // Formulaire OK
                console.log(ref, 'is valid');
                ref.current.parentElement.classList.remove(styles.invalidForm);
                ref.current.parentElement.classList.add(styles.validForm);
                removeItemOnce(ref.current.getAttribute('formvalue'));
            } else {
                // Formulaire non valide
                console.log(ref, 'is not valid');
                ref.current.parentElement.classList.remove(styles.validForm);
                ref.current.parentElement.classList.add(styles.invalidForm);
                addItemIfNotPresent(ref.current.getAttribute('formvalue'));
            }
        });
        validationComplete.current = true;
        setTimestamp(Date.now());
    }
        
    useEffect(() => {
        if(validationComplete.current) {
            if(warning.length === 0) {
                handleClickForDataLayer();
                // company.form.fastlane ? navigate('/form/dealname') : navigate('/form/self-employed')
                company.form.fastlane ? navigate('/form/dealname') : navigate('/form/dealname')
            }
            validationComplete.current = false;
        }
    }, [warning, validationComplete.current, company.form.fastlane, timestamp]);

    return (
        <div className="self-step main-9">
            <div className="global">
                <div className="questions">C'est parti ! Pouvez-vous renseigner ces quelques informations ?</div>
                <div className={`answers ${styles.answers}`}>
                    <div className={styles.list}>
                        <form id="connection-form" ref={connectionForm}>
                            <div className="connection">
                                <div className={`answers-row ${styles["answers-row"]} ${styles.name_input}`}>
                                    <div className={`answer ${styles.answer}`}>
                                        <label className={`label ${styles.label}`} htmlFor="firstname">Votre prénom</label>
                                        <input type="text" id="form.contact.firstname" name="form.contact.firstname" formvalue="Prénom" className={styles["form-login"]} placeholder="Jonh" value={company.form.contact.firstname} onChange={handleAllChange} ref={firstNameRef} required/>
                                    </div>
                                    <div className={`answer ${styles.answer}`}>
                                        <label className={`label ${styles.label}`} htmlFor="lastname">Votre nom</label>
                                        <input type="text" id="form.contact.lastname" name="form.contact.lastname" formvalue="Nom" className={styles["form-login"]} placeholder="Doe" value={company.form.contact.lastname} onChange={handleAllChange} ref={lastNameRef} required/>
                                    </div>
                                </div>
                                <div className={`answers-row ${styles["answers-row"]} ${styles.coords}`}>
                                    <div className={`answer ${styles.answer}`}>
                                        <label className={`label ${styles.label}`} htmlFor="mail">Votre e-mail</label>
                                        <input type="email" id="form.contact.email" name="form.contact.email" formvalue="E-mail" className={styles["form-login"]} placeholder="jonh@comptastar.fr" value={company.form.contact.email} onChange={handleAllChange} ref={emailRef} required/>
                                    </div>
                                    <div className={`answer ${styles.answer}`}>
                                        <label className={`label ${styles.label}`} htmlFor="mobile">Votre numéro de téléphone</label>
                                        <input type="tel" id="form.contact.phone" name="form.contact.phone" formvalue="Numéro de téléphone" className={styles["form-login"]} placeholder="+33 6 07 08 09 10" value={company.form.contact.phone} onChange={handleAllChange} ref={phoneRef} required/>
                                    </div>
                                </div>
                            </div>
                            {
                                warning.length > 0 ? <div className={styles.warning}>Veuillez renseigner {warning.length === 1 ? "le champ suivant" : "les champs suivants"} : {warning.join(', ')}</div> : null
                            }
                            <div onClick={handleClickForm} className={`cta ${styles.cta}`}>Continuer</div>
                            {/* <Link onClick={handleClickForDataLayer} to={company.form.fastlane ? '/form/dealname' : '/form/self-employed'}><div className={`cta ${styles.cta}`}>Continuer</div></Link> */}
                            {/* <Link onClick={handleClickForDataLayer} to={company.form.fastlane ? '/form/dealname' : '/form/self-employed'}><div className="later">Je souhaite répondre plus tard.</div></Link> */}
                        </form>
                    </div>
                    {/* <div className={styles.spliter}>
                        <div className={styles.line}></div>
                        <div className={styles.label}>OU</div>
                        <div className={styles.line}></div>
                    </div>
                    <GoogleSignIn onUserAuthenticated={handleUserAuthenticated}/> */}
                </div>
                <div className="main-details">
                    <div className="details">🌟 {creation_count} {company.form.legal_statuts} ont été créées le mois dernier en France. 🇫🇷</div>
                </div>
                <div className="actions">
                    <Link to='/form/legal-statuts'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <div className="next" style={{display: 'none'}}>Continuer</div>
                </div>
            </div>         
        </div>
    )
}
export default ContactInformations;