import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './domiciliation.module.css';
import dataLayerPush from '../../../utils/data-layer';

function Domiciliation() {
    const { handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest(`.${styles['service-choice']}`);
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            handleClickForDataLayer();
            navigate('/form/bank');
        }
    }

    const handleClickForDataLayer = () => {
        dataLayerPush({
        event: 'Clickbutton',
        eventLabel: 'Step 10',
        });
    };

    return (
        <div className="self-step main-17">
            <div className="global">
                <div className="questions">Domiciliez votre entreprise et bénéficiez de nombreux avantages.</div>
                <div className={`answers ${styles.answers}`} question='form.domiciliation'>
                    <div className={`bullet-points ${styles['bullet-points']}`}>
                        <div className={`bullet-point ${styles['bullet-point']}`}>
                            <div className={styles.emoji}>🔑</div>
                            <div className={styles.affirmation}>Confidentialité & Sécurité</div>
                            <div className={`description ${styles.description}`}>Dissociez votre adresse personnelle de l'adresse de votre entreprise.</div>
                        </div>
                        <div className={`bullet-point ${styles['bullet-point']}`}>
                            <div className={styles.emoji}>🏛️</div>
                            <div className={styles.affirmation}>Adresse de prestige</div>
                            <div className={`description ${styles.description}`}>Pour rassurer vos clients et gagner en confiance, choisissez le 66 Avenue des Champs Elysées.</div>
                        </div>
                        <div className={`bullet-point ${styles['bullet-point']}`}>
                            <div className={styles.emoji}>🧾</div>
                            <div className={styles.affirmation}>Réduction d'impôts</div>
                            <div className={`description ${styles.description}`}>Jusqu'à 850€ d'économies par an tous les ans.</div>
                        </div>
                    </div>
                    <div choice='true' onClick={handleSubmit} className={`cta ${styles['service-choice']}`}>Choisir ce service</div>
                    <div choice='false' onClick={handleSubmit} className={`later ${styles['service-choice']}`}>Je ne souhaite pas bénéficier des ces avantages.</div>
                </div>
                <div className="main-details">
                    <div className="details">🌟 Notre merveilleux partenaire Se Domicilier vous appellera dans les jours à venir pour s'en occuper à votre place avec une belle réduction sur leur abonnement. Vous allez voir, leur équipe est très sympa et efficace !</div>
                </div>
                <div className="actions">
                    <Link to='/form/craft-activity'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <div className="next" style={{display: 'none'}}>Continuer</div>
                </div>
            </div>         
        </div>
    )
}

export default Domiciliation;