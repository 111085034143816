export async function handleFormSubmit(email, firstname, lastname, rawPhone, legal_statuts, company, setCompany, handleChange) {
    const re = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
    if (email !== '' && re.test(email)) {
        console.log('email connu et validé');
        const myHeaders = new Headers({ "Content-Type": "application/json" });
        var regime_fiscal;
        switch (legal_statuts) {
            case 'Auto-entrepreneur':case 'Entreprise individuelle':
                regime_fiscal = 'IR';
                break;
            default:
                regime_fiscal = 'IS';
                break;
        }

        function formatPhoneNumber(phoneNumber) {
            const regex = /(?:\+33\s*0?|0)[\s-]*(6)[\s-]*([0-9]{2})[\s-]*([0-9]{2})[\s-]*([0-9]{2})[\s-]*([0-9]{2})/;
            return phoneNumber.replace(regex, "+33$1$2$3$4$5");
        }

        const phone = await formatPhoneNumber(rawPhone);

        const raw = JSON.stringify({
            postFields: {
                contact: { properties: { email, firstname, lastname, phone } },
                deal: { properties: { dealname: `${lastname.toUpperCase()} ${firstname} | ${legal_statuts}`, dealstage: "267376317", forme_juridique: legal_statuts, regime_fiscal: regime_fiscal } }
            }
        });
        console.log(raw);

        const requestOptions = { method: "POST", headers: myHeaders, body: raw, redirect: "follow" };
        fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}self-onboarding/hubspot/deals/new.php`, requestOptions)
        .then(response => response.json())
        .then(async hubspot => {
            setCompany({...company, form: {...company.form, transaction_id: hubspot.deal_id, contact_id: hubspot.contact_id}});
            // Ajout abonnement annuel comptabilité
            var data = {};
            var price = 0;
            const today = new Date();
            const futureDate = new Date();
            futureDate.setDate(today.getDate() + 15);
            const futureDateString = futureDate.toISOString().split('T')[0];
            if(company.form.accounting.contract === "annually") {
                if(company.form.legal_statuts === "Auto-entrepreneur") {
                    //Modification du nom du produit pour l'abonnement annuel
                    if(company.form.fastlane) {
                        price = 29 * 12;
                    } else {
                        price = 39 * 12;
                    }
                } else {
                    if(company.form.fastlane) {
                        price = 49 * 12;
                      } else {
                        price = 89 * 12;
                      }
                }
                data = {
                    deal_id: hubspot.deal_id,
                    hs_product_id: 1588126665,
                    price: price,
                    hs_recurring_billing_start_date: futureDateString
                };
            } else {
                if(company.form.legal_statuts === "Auto-entrepreneur") {
                    if(company.form.fastlane) {
                        price = 39;
                      } else {
                        price = 39;
                      }
                } else {
                    if(company.form.fastlane) {
                        price = 49;
                    } else {
                        price = 89;
                    }
                }
                data = {
                    deal_id: hubspot.deal_id,
                    hs_product_id: 1379550667,
                    price: price,
                    hs_recurring_billing_start_date: futureDateString
                };
            }
            lineItemCreation(data, `hubspot_product.subscriptions.accounting`, handleChange);

            // Ajout assemblée générale
            var data = {};
            var price = 0;
            if(company.form.legal_statuts !== "Auto-entrepreneur") {
                if(company.form.accounting.contract === "annually") {
                    price = 0;
                } else {
                    price = 199;
                }
                data = {
                    deal_id: hubspot.deal_id,
                    hs_product_id: 1290922450,
                    price: price,
                    hs_recurring_billing_start_date: futureDateString
                };
                // lineItemCreation(data, `hubspot_product.subscriptions.ag`, handleChange);
            }
        })
    }
}

async function lineItemCreation(data, companyPath, handleChange) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify(data);
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };
    try {
        const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/hubspot/line_items/create`, requestOptions);
        const result = await response.json();
        console.log({'name': companyPath + '.product', 'value': result.line_item.response.properties.hs_product_id}, {'name': companyPath + '.line_item', 'value': result.line_item.response.properties.hs_object_id}, 'result lineItemCretion');
        handleChange({'name': companyPath + '.product', 'value': result.line_item.response.properties.hs_product_id});
        handleChange({'name': companyPath + '.line_item', 'value': result.line_item.response.properties.hs_object_id});
    } catch (error) {
        console.error(error);
    };
}