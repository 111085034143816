import React, { useContext, useEffect, useRef } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';

function CaEstimation() {
    const button_minus_180 = useRef(null);
    const button_plus_180 = useRef(null);
    const button_minus_77 = useRef(null);
    const button_plus_77 = useRef(null);
    const { company, handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            navigate('/tax-assistant/charges');
        }
    }
    useEffect(() => {
        const activity_type = company.assistant.activity_type;
        if(["sales_of_goods"].includes(activity_type)) {
            button_minus_180.current.style.display = 'block';
            button_plus_180.current.style.display = 'block';
            button_minus_77.current.style.display = 'none';
            button_plus_77.current.style.display = 'none';
        } else {
            button_minus_180.current.style.display = 'none';
            button_plus_180.current.style.display = 'none';
            button_minus_77.current.style.display = 'block';
            button_plus_77.current.style.display = 'block';
        }
    })
    return (
        <div className="self-step main-5">
            <div className="global">
                <div className="questions">Très clair, quel est votre <span className="important">chiffre d'affaires estimé</span> sur la première année ?</div>
                <div className="answers">
                    <div className="multiform" question='assistant.ca_estimation'>
                        <div className="big-button" onClick={handleSubmit} choice="- de 180 000€" ref={button_minus_180}>
                            <div className="emoji">➖</div>
                            <div className="affirmation">Moins de 180 000€</div>
                            <div className="description"></div>
                            <div className="subtitle">Je pense débuter sereinement sans prendre trop de risques.</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="+ de 180 000€" ref={button_plus_180}>
                            <div className="emoji">➕</div>
                            <div className="affirmation">Plus de 180 000€</div>
                            <div className="description"></div>
                            <div className="subtitle">Je suis ambitieux et je pense faire un excellent démarrage !</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="- de 77 700€" ref={button_minus_77}>
                            <div className="emoji">➖</div>
                            <div className="affirmation">Moins de 77 700€</div>
                            <div className="description"></div>
                            <div className="subtitle">Je pense débuter sereinement sans prendre trop de risques.</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="+ de 77 700€" ref={button_plus_77}>
                            <div className="emoji">➕</div>
                            <div className="affirmation">Plus de 77 700€</div>
                            <div className="description"></div>
                            <div className="subtitle">Je suis ambitieux et je pense faire un excellent démarrage !</div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <Link to='/tax-assistant/remunerated'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link to='/tax-assistant/charges'><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}
export default CaEstimation;