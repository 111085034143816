import React, { useEffect, useRef, useContext, useState } from "react";
import { UserContext } from "../../../App";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./payment-success.module.css";

function PaymentSuccess() {
    const { company, setCompany } = useContext(UserContext);
    const { payment_method, transaction_id } = useParams();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    let pid = searchParams.get('pid');
    let installment_count = searchParams.get('installment_count');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if(payment_method && company.form.transaction_id) {
                if(payment_method === 'alma' || payment_method === 'paypal' || payment_method === 'sepa') {
                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
    
                    const raw = JSON.stringify({
                        "payment_method": payment_method,
                        "transaction_id": company.form.transaction_id,
                        "payment_id": pid,
                        "installment_count": installment_count || "1"
                    });
    
                    const requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow"
                    };
    
                    try {
                        const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/payments/verify`, requestOptions);
                        const result = await response.json();
                        console.log(result);
                        setLoading(false);
                    } catch (error) {
                        setError(true);
                        console.error(error);
                    };
                }
            }
        };
    
        fetchData();
    }, [company.form.transaction_id, payment_method, pid]);

    return (
        <div className="self-step main-23">
            <div className="global">
                {loading && !error && 
                    <>
                        <div className="questions">Vérification de votre paiement</div>
                        <div className={styles.subtitle}>
                            Nous vérifions votre paiement. Cela peut prendre quelques secondes, ne quittez pas cette page. Merci.
                        </div>
                    </>
                }
                {error &&
                    <>
                        <div className="questions">Erreur lors de la vérification de votre paiement</div>
                        <div className={styles.subtitle}>
                            Une erreur s'est produite. Revenez en arrière et réessayez. Si le problème persiste, contactez-nous.
                        </div>
                    </>
                }
                {!loading && !error &&
                    <>
                        <div className="questions">Paiement vérifié</div>
                        <div className={styles.subtitle}>
                            Vous allez être redirigé vers la signature de votre contrat.
                        </div>
                    </>
                }
            </div>    
        </div>
    )
}
export default PaymentSuccess;