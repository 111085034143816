import React, { useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';

const NewCompany = () => {
  const location = useLocation();
  const { setCompany, handleChange } = useContext(UserContext);
  const navigate = useNavigate();

  const totalPathToIndex = {
    "/": 0,
    "/direction": 0,
    "/tax-assistant/associates": 1,
    "/tax-assistant/activity-type": 2,
    "/tax-assistant/remunerated": 3,
    "/tax-assistant/ca-estimation": 4,
    "/tax-assistant/charges": 5,
    "/tax-assistant/result": 6,
    "/form/legal-statuts": 7,
    "/form/legal-statuts/SASU": 7,
    "/form/contact-informations": 8,
    "/form/self-employed": 9, // fastlane
    "/form/already-set_up-business": 10,// fastlane
    "/form/work-as": 11, // fastlane
    "/form/dealname": 12,
    "/form/register-your-trademark": 13, // fastlane
    "/form/activity": 14, // fastlane
    "/form/craft-activity": 15, // fastlane
    "/form/domiciliation": 16, // fastlane
    "/form/bank": 17, // fastlane
    "/form/accounting": 18,
    "/form/recap": 19,
    "/form/legal-pack": 20,
    "/form/merchant-recap": 21,
    "/form/checkout": 22,
    "/form/payment-success": 23,
    "/form/payment-success/alma": 23,
    "/form/payment-success/paypal": 23,
    "/form/payment-success/sepa": 23,
    "/form/contract": 24,
    "/form/success": 25,
    "dynamic": (path) => {
      // Gérer les cas de chemins dynamiques avec ID
      const dynamicRegex = /^\/\d+$/;
      if (dynamicRegex.test(path)) {
        return 0; // L'indice pour les URLs avec un ID
      }
      return -1; // Un indice qui indique une non-correspondance
    }
  }

  const getPathIndex = (path) => {
    if (totalPathToIndex[path] !== undefined) {
      return totalPathToIndex[path];
    } else if (typeof totalPathToIndex['dynamic'] === 'function') {
      return totalPathToIndex['dynamic'](path);
    }
    return -1; // Fallback si aucune correspondance n'est trouvée
  }
  
  async function resetCompanyInfo() {
    await handleChange({
      'name': 'form.transaction_id',
      'value': ''
    });
    await setCompany({
        'assistant': {
          "solo_associate": "", // true, false
          "activity_type": "", // service_provision, sales_of_goods, service_provision_liberal, service_provision_craftsman
          "remunerated": "", // pay_slip, immediate, dividends, complementary_salaried_employment
          "ca_estimation": "", // - de 180 000€, - de 77 700€, + de 77 700€, + de 180 000€
          "charges": "" // - de 34%, + de 34%
        }, 
        'form': {
          'legal_statuts': '', // SAS | SASU, SARL | EURL, Auto-entrepeneur, Entreprise individuelle, Association
          'contact': {
            "firstname": "",
            "lastname": "",
            "email": "",
            "phone": "",
            "google": ""
          },
          "last_page": null,
          "fastlane": false,
          "transaction_id": "",
          "contact_id": "",
          "already-set_up-business": "", // self-employed,  already_set_up, never
          "work_as": "", // Entrepreneur, Salarié, Demandeur d'emploi, Étudiant,
          "dealname": "",
          "activity": "", // E-commerce, Import / Export, Alimentation / Restauration, BTP / Aménagement, Conseils / Consultant, Logiciel SaaS / Applications, Transport, Autres
          "other_activity": "",
          "craft_activity": null, // true, false
          "self_employed": null, // true, false
          "domiciliation": null, // true, false
          "bank": null, // true, false
          "accounting": {
            "contract": "annually", // annually, monthly
          },
          "legal_pack": "", // Premium & Réussite, Standard, Débutant & Seul
          "promo_code_value": "",
          "promo_code_pourcentage": "",
          "upsell": {
            "deregistration": false, // true, false
            "bylaws_word": false, // true, false
            "register_your_trademark": null, // true, false
            "kbis_by_post": false, // true, false
            "canvassing": false, // true, false
            "logo": false, // true, false
            "brand_identity": false, // true, false
            "business_card": false, // true, false
            "flyers": false, // true, false
            "domain_name_protection": false, // true, false
            "computer_scientict": false, // true, false
            "mail_signature": false, // true, false
            "community_management": false, // true, false
            "social_community_management": {
              'instagram': true,
              'facebook': true,
              'tiktok': false,
              'twitter': false,
              'snapchat': false,
              'pinterest': false,
              'linkedin': false
            }
          }
        },
        "hubspot_product": {
          "one_off": {
            legal_pack: {
              product: null,
              line_item: null
            },
            register_your_trademark: {
              product: null,
              line_item: null
            },
            domiciliation: {
              product: null,
              line_item: null
            },
            bank: {
              product: null,
              line_item: null
            },
            bylaws_word: {
              product: null,
              line_item: null
            },
            canvassing: {
              product: null,
              line_item: null
            },
            logo: {
              product: null,
              line_item: null
            },
            brand_identity: {
              product: null,
              line_item: null
            },
            business_card: {
              product: null,
              line_item: null
            },
            flyers: {
              product: null,
              line_item: null
            },
            domain_name_protection: {
              product: null,
              line_item: null
            },
            computer_scientict: {
              product: null,
              line_item: null
            },
            mail_signature: {
              product: null,
              line_item: null
            },
            community_management: {
              product: null,
              line_item: null
            },
            deregistration: {
              product: null,
              line_item: null
            },
            kbis_by_post: {
              product: null,
              line_item: null
            }
          },
          "subscriptions": {
            accounting: {
              product: null,
              line_item: null
            },
            ag: {
              product: null,
              line_item: null
            }
          }
        },
        "shoppingCart": {
          legal_pack: null, // 229, 169, 0
          court_fees: 259.39,
          accounting: 0, // 89, 119, 39, 348
          kbis_by_post: 0, // 0, 19.90
          register_your_trademark: 0, // 0, 269
          bylaws_word: 0, // 0, 9.90
          canvassing: 0, // 0, 29.9
          logo: 0, // 0, 149
          brand_identity: 0, // 0, 139
          business_card: 0, // 0, 99
          flyers: 0, // 0, 99
          domain_name_protection: 0, // 0, 199
          computer_scientict: 0, // 0, 399
          mail_signature: 0, // 0, 99
          community_management: 0, // 0
          general_meeting: 0, // 0, 119
          deregistration: 0 // 0, 169
        },
        "sepa": {
          "billing_request_id": null
        },
        "payment": {
          "payment_solution": null,
          "payment_id": null
        },
        "contract": {
          "id": null,
          "participant_id": null,
          "url": null,
          "is_signed": false
        }
      });
      console.log('company reset');
      navigate('/form/legal-statuts');
  }

  return (
    (getPathIndex(location.pathname) === 25) ? (
      <div className='prev_url'>
        <div className='prev_paragraphe'>
            Vous êtes un serial entrepreneur ? <span className='bold'>Envisagez-vous de créer une entreprise supplémentaire ?</span>
        </div>
        <div onClick={resetCompanyInfo} className='prev_button'>C'est par ici</div>
      </div>
    ) : (
      <></>
    )
  );
}

export default NewCompany;