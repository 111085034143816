import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../../App';
import { Link } from 'react-router-dom';
import styles from './accounting.module.css';
import story from './story.module.css';
import toggle from './toggle-switch.module.css';
import dataLayerPush from '../../../utils/data-layer';

function Accounting() {
    // const toggle_switch = useRef(null);
    // const cta_monthly = useRef(null);
    // const cta_annually = useRef(null);

    const [ monthlyPrice, setMonthlyPrice ] = useState(119);
    const [ annuallyPrice, setAnnuallyPrice ] = useState(89);
    const [ savings, setSavings ] = useState(432);

    let choice = '';

    const { company, handleChange } = useContext(UserContext);
    function goToMonthly() {
        // toggle_switch.current.classList.add(toggle.active);
        // cta_annually.current.classList.remove(styles['pre-choice']);
        // cta_monthly.current.classList.add(styles['pre-choice']);
        setSavings(0);
        // cta_next.current.innerHTML = 'Valider';
    }
    function goToAnnually() {
        // toggle_switch.current.classList.remove(toggle.active);
        // cta_annually.current.classList.add(styles['pre-choice']);
        // cta_monthly.current.classList.remove(styles['pre-choice']);
        setSavings(monthlyPrice*12 - annuallyPrice*12);
        // cta_next.current.innerHTML = `Valider et réaliser ${savings}€ d\'économies`;
    }
    function handleSubmit(event) {
        event.preventDefault();
        const toggle_choice = event.target.closest(`.${toggle.switch}`);
        const button_choice = event.target.closest(`.${styles.formula}`);
        if(toggle_choice) {
            const actual_choice = company.form.accounting.contract;
            console.log(actual_choice)
            if(actual_choice === 'annually') {
                choice = 'monthly';
                goToMonthly();
            } else if(actual_choice === 'monthly') {
                choice = 'annually';
                goToAnnually();
            } else {
                choice = 'monthly';
                goToMonthly();
            };
            console.log(actual_choice, ' to ', choice);
            handleChange({
                'name': 'form.accounting.contract',
                'value': choice
            });
        } else if(button_choice) {
            const actual_choice = company.form.accounting.contract;
            const choice = button_choice.getAttribute('choice');
            if(actual_choice !== choice) {
                if(choice === 'annually') {
                    goToAnnually();   
                } else {
                    goToMonthly();    
                }
            }
            handleChange({
                'name': 'form.accounting.contract',
                'value': choice
            });
        }
    }
    useEffect(() => {// Todo
        company.form.accounting.contract === 'monthly' ? goToMonthly() : goToAnnually();
        if(company.form.legal_statuts === "Auto-entrepreneur") {
            if(company.form.fastlane) {
                setAnnuallyPrice(29);
                setMonthlyPrice(39);//39
            } else {
                setAnnuallyPrice(39);
                setMonthlyPrice(39);//39
            }
        } else {
            if(company.form.fastlane) {
                setAnnuallyPrice(49);
                setMonthlyPrice(49);//49
            } else {
                setAnnuallyPrice(89);
                setMonthlyPrice(89);//89
            }
        }
    }, [company.form.legal_statuts, company.form.fastlane, company.form.accounting.contract])

    const handleClickForDataLayer = () => {
        dataLayerPush({
            event: 'Clickbutton',
            eventLabel: 'Step 12',
        });
    };

    return (
        <div className="self-step main-19">
            <div className={`global ${styles.global}`}>
                <div className="questions">Votre formule de comptabilité</div>
                <div className={`${styles.details}`} style={{marginBottom: '10px'}}>
                    🌟 Comptastar gère la comptabilité de votre société de A à Z. Et vous offre les 15 premiers jours.
                </div>
                <div className={`sub-details ${styles['sub-details']}`}>
                    <div className={styles.arrow}>Bilan, liasse fiscale, compte de résultat, annexes, déclaration de TVA et télédéclaration au centre des impôts</div>
                    <div className={styles.arrow}>Comptable dédié avec des conseils en juridique, social et fiscalité</div>
                    <div className={styles.arrow}>Logiciel comptable intelligent en illimité (suivi de votre trésorerie et de la comptabilité en instantané)</div>
                </div>
                {/* <div className={styles.accounting_contract}>
                    <div className={`${styles.formula}`} choice="monthly" ref={cta_monthly} onClick={handleSubmit}>
                        <span className={styles.primary}>Mensuel</span> | {monthlyPrice} € / mois
                    </div>
                    <div className={toggle.toggle_switch} onClick={handleSubmit}>
                        <label className={`${toggle.switch}`} ref={toggle_switch}>
                            <span className={`${toggle.slider}`}></span>
                        </label>
                    </div>
                    <div className={`${styles.formula}`} choice="annually" ref={cta_annually} onClick={handleSubmit}>
                        <div className={`${styles.reinsurance} ${styles.important}`}>Recommandé par 95% des clients</div>
                        <span className={styles.primary}>Annuel</span> | {annuallyPrice} € / mois
                    </div>
                </div> */}
                {/* {
                    (company.form.accounting.contract === "annually") && (
                        <div className={styles['offer-detail']} trigger="annually">
                            <div className={styles.title}>🏆 {savings} € d'économisés</div>
                            <div className={styles.title}>✅ Facturation simple en 1 mois</div>
                            <div className={styles.title}>✅ 1 an de tranquillité</div>
                            {
                                company.form.legal_statuts !== "Auto-entrepreneur" && (
                                    <div className={styles.description}>
                                        Assemblée générale offerte la <div>1<span className={styles.exhibitor}>ère</span> année. <div className={`question overlay`}>?<span className="answer"><span style={{textDecoration: 'underline'}}>Tous les ans, l'assemblée générale permet aux associés de valider les comptes annuels de la société.</span> Approuver les comptes de l'entreprise signifie que les associés vont valider la bonne gestion de l'entreprise, valider les prises de décision, mais également décider de l'affectation du résultat si les chiffres sont positifs.</span></div></div>
                                    </div>
                                )
                            }
                        </div>
                    )
                } */}
                {/* {
                    (company.form.accounting.contract === "monthly") && (
                        <div className={styles['offer-detail']} trigger="monthly">
                            <div className={styles.title}>👆 Vous pourrez étaler les honoraires en payant tous les mois.</div>
                            {
                                company.form.legal_statuts !== "Auto-entrepreneur" && (
                                    <div className={styles.description}>Assemblée générale : 119 € / an <div className={`question overlay`}>?<span className="answer"><span style={{textDecoration: 'underline'}}>Tous les ans, l'assemblée générale permet aux associés de valider les comptes annuels de la société.</span> Approuver les comptes de l'entreprise signifie que les associés vont valider la bonne gestion de l'entreprise, valider les prises de décision, mais également décider de l'affectation du résultat si les chiffres sont positifs.<br /><br /><span style={{textDecoration: 'underline'}}>Ce montant sera prélevé une fois la mise en place de votre abonnement Comptastar mis en place.</span></span></div></div>
                                )
                            }
                        </div>
                    )
                } */}
                <div className="answers">
                    <Link onClick={handleClickForDataLayer} to='/form/recap'><div className={`cta ${styles.button}`}>Valider ma comptabilité à {monthlyPrice}€ / mois</div></Link>
                </div>
                <div className={story['our-story']}>
                    <div className='questions'>Pourquoi Comptastar est le comptable qu'il me faut ?</div>
                    <div className={story['didier-presentation']}>
                        <img alt="Président de Comptastar Didier" src="/didier.png" className={story.fst}/>
                        <img alt="Président de Comptastar Didier plus rapproché" src="/didier-2.png" className={story.scd}/>
                    </div>

                    <div className={story['main-value']}>
                        <div className={story.left}>
                            <div className={story.value}>
                                <div className={story.icon}>✅</div>
                                <h2>Expertise</h2>
                                <div className={story.description}>Membre de l'<span className={story.bold}>Ordre des Experts-Comptables</span> et spécialisé dans la comptabilité et la création de sociétés, Comptastar vous accompagne sur <span className={story.bold}>tous les projets de votre entreprise</span> (missions sociales, juridiques et fiscales). Le plus ? Votre Comptable dédié sera un expert de votre secteur d'activité.</div>
                            </div>
                            <div className={story.value}>
                                <div className={story.icon}>💰</div>
                                <h2>Tarifs justes</h2>
                                <div className={story.description}>Clé en main et sans engagement, optimisez facilement vos coûts comptables et optimisez fiscalement votre activité. <span className={story.bold}>Réalisez des économies</span> sans dégrader le service comptable.</div>
                            </div>
                        </div>
                        <div className={story.right}>
                            <div className={story.value}>
                                <div className={story.icon}>📱</div>
                                <h2>Simplicité</h2>
                                <div className={story.description}>Profitez de la <span className={story.bold}>meilleure application</span> (sur Mobile et Ordinateur) de comptabilité du marché et <span className={story.bold}>restez connecté à votre comptable dédié</span>. En un clin d'oeil, consultez vos factures, flux de trésorerie et les documents comptables et fiscaux.</div>
                            </div>

                            <div className={story.value}>
                                <div className={story.icon}>🤝</div>
                                <h2>Accompagnement</h2>
                                <div className={story.description}>Avec <span className={story.bold}>plusieurs milliers de Français accompagnés</span> par le cabinet, Comptastar est présent partout en France* et vous épaule à toutes les étapes de votre activité : consulting, juridique, conseils comptables & fiscaux. <br /><br /><span>📍 *Bordeaux, Paris et Perpignan</span></div>
                            </div>
                        </div>
                        <div className={`${story.value} ${story.satisfaction}`}>
                            <div className={story.icon}>😍</div>
                            <h2>Satisfaction</h2>
                            <div className={story.description}>Comptastar rend heureux ses clients et c'est prouvé ! <br /> Plus de <span className={story.bold}>100 000 Français satisfaits</span>.</div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <Link to={company.form.fastlane ? '/form/dealname' : '/form/dealname'}><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <div className="next" style={{display: 'none'}}>Continuer</div>
                </div>
            </div>         
        </div>
    )
}

export default Accounting;