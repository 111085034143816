import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './work-as.module.css'

function AlreadySetUpBusiness() {
    const { handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button, .later');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            navigate('/form/dealname');
        }
    }

    return (
        <div className="self-step main-11">
            <div className="global">
                <div className="questions">C'est noté ! Actuellement, quelle est votre <span className="important">situation professionnelle</span> ?</div>
                <div className="answers" question='form.work_as'>
                    <div className="multiform" question='form.work_as'>
                        <div className={`big-button ${styles['big-button']}`} choice='Entrepreneur' onClick={handleSubmit}>
                            <div className="affirmation">Entrepreneur</div>
                            <div className="description"></div>
                        </div>
                        <div className={`big-button ${styles['big-button']}`} choice='Salarié' onClick={handleSubmit}>
                            <div className="affirmation">Salarié</div>
                            <div className="description"></div>
                        </div>
                        <div className={`big-button ${styles['big-button']}`} choice="Demandeur d'emploi" onClick={handleSubmit}>
                            <div className="affirmation">Demandeur d'emploi</div>
                            <div className="description"></div>
                        </div>
                        <div className={`big-button ${styles['big-button']}`} choice='Étudiant' onClick={handleSubmit}>
                            <div className="affirmation">Étudiant</div>
                            <div className="description"></div>
                        </div>
                    </div>
                    <div className={`later ${styles.later}`} onClick={handleSubmit} choice=''>Je souhaite répondre plus tard.</div>
                </div>
                <div className="actions">
                    <Link to='/form/already-set_up-business'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link to='/form/dealname'><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}

export default AlreadySetUpBusiness;