import styles from './navbar.module.css';
import { Link } from 'react-router-dom';

function NavBar() {
    return (
        <div className={styles.navbar}>
            <div className={styles.sub}>
                <div className={styles.information} style={{borderRadius: 0}}>
                    <div className={styles.logo}>
                        <img alt='Logo Comptastar' src="/logo.png"/>
                    </div>
                    <div className={styles.cta_phone}>                
                        <span className={`${styles.number} ${styles.standard}`}><img alt='Icone de téléphone' src='/icon/black_phone.svg' className={styles.icon}/> <span><Link to='tel:+33745898101'>07 45 89 81 01</Link></span></span>
                        <span className={styles.desc}>de 9h à 18h</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NavBar;
