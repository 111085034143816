import React from 'react';
import { Link } from 'react-router-dom';
import dataLayerPush from '../../utils/data-layer';

function Direction() {
  const handleClickForDataLayer = () => {
    dataLayerPush({
      event: 'Clickbutton',
      eventLabel: 'Step 2',
    });
  };

    return (
      <div className={`self-step main-1`}>
        <div className={'global'}>
          <div className={'questions'}>Connaissez-vous <span className={'important'}>le statut juridique</span> de votre future entreprise ?</div>
          <div className={'answers'}>
            <div className={'multiform'}>
              <Link to="/form/legal-statuts" onClick={handleClickForDataLayer}>
                <div className={"big-button"}>
                    <div className={"emoji"}>🙋</div>
                    <div className={"affirmation"}>Oui,</div>
                    <div className={"description"}>je le connais déjà !</div>
                    <div className={"subtitle"}>SASU, SAS, SARL</div>
                </div>
              </Link>
              <Link to="/tax-assistant/associates" onClick={handleClickForDataLayer}>
                <div className={`big-button`}>
                    <div className={"emoji"}>🙅</div>
                    <div className={"affirmation"}>Non,</div>
                    <div className={"description"}>j'ai besoin de conseils.</div>
                    <div className={"subtitle"}>Répondez à ces questions en 30 secondes pour déterminer la meilleure forme juridique.</div>
                </div>
              </Link>
            </div>
          </div>
          <div className={'details'} style={{ display: 'none' }}></div>
          <div className={'actions'} style={{ justifyContent: 'flex-end' }}>
              <div className={'prev'} style={{ display: 'none' }}><div className={'arrow'}></div>Retour</div>
              <Link to="/tax-assistant/associates" onClick={handleClickForDataLayer}><div className={'next'}>Continuer</div></Link>
          </div>
        </div>  
      </div>
    );
  }

  export default Direction;