import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './register-your-trademark.module.css';
import dataLayerPush from '../../../utils/data-layer';

function RegisterYourTrademark() {
    const { company, handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button, .cta');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            handleClickForDataLayer();
            navigate('/form/activity');
        }
    }
    const details = '🌟 Nous vous recommandons vivement la protection de votre marque par nos soins pour éviter qu\'un concurrent ne se l\'approprie et porte atteinte à votre entreprise. Comptastar s\'occupe de tout très rapidement :';


    const handleClickForDataLayer = () => {
        dataLayerPush({
        event: 'Clickbutton',
        eventLabel: 'Step 7',
        });
    };

    return (
        <div className="self-step main-13">
            <div className="global">
                <div className="questions">{company.form.dealname !== '' && <span>Ça sonne bien <span className={styles.legal_name}>{company.form.dealname}</span> ! </span>}Voulez-vous protéger le nom de votre société en <span className="important">déposant votre marque</span> ?</div>
                <div className="answers">
                    <div className="multiform" question="form.upsell.register_your_trademark">
                        <div className={`big-button ${styles.button}`} choice="true" onClick={handleSubmit}>
                            <div className="reinsurance important">Le plus populaire</div>
                            <div className="emoji">🔐</div>
                            <div className="affirmation">Je protège évidemment ma marque !</div>
                            <div className="description"></div>
                            <div className="subtitle">Je suis le seul à pouvoir l'utiliser en France sans craindre certains concurrents.</div>
                        </div>
                        <div className="big-button" choice="false" onClick={handleSubmit}>
                            <div className="emoji">🔓</div>
                            <div className="affirmation">Je n'ai pas besoin de protéger mon nom de marque.</div>
                            <div className="description"></div>
                            <div className="subtitle">Je peux laisser quelqu'un utiliser le même nom.</div>
                        </div>
                    </div>
                    <Link to='/form/activity' onClick={handleClickForDataLayer}><div className="cta">Continuer</div></Link>
                </div>
                <div className="main-details">
                    <div className="details" style={{marginBottom: '10px'}}>{details}</div>
                    <div className={styles.sub_details}>
                        <div className={styles.arrow}>Recherche d'antériorité pour savoir si votre marque est disponible (recherche à l'identique)</div>
                        <div className={styles.arrow}>Gestion complète des formalités de dépôt de marque auprès de l'INPI</div>
                        <div className={styles.arrow}>Accompagnement par des Experts (information juridique à caractère documentaire)</div>
                    </div>
                </div>
                <div className="actions">
                    <Link to='/form/dealname'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <div className="next" style={{display: 'none'}}>Continuer</div>
                </div>
            </div>         
        </div>
    )
}

export default RegisterYourTrademark;