import React, { useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';
import dataLayerPush from '../../utils/data-layer';
import { ref, get } from 'firebase/database';
import { db } from '../../config/firebase';

function Home() {
  const { special } = useParams();
  const { company, setCompany, handleChange } = useContext(UserContext);
  let navigate = useNavigate();

  useEffect(() => {
    if(special === 'fast') {
      console.log('useEffect handleChange called', );
      handleChange({
        'name': 'form.fastlane',
        'value': true
      });
    } else if (special !== undefined && special !== null && special !== '') {
      console.log('Initialisation Firebase, transaction_id:', special);
      const fetchData = async () => {
        const dbRef = ref(db, '/self-onboarding/form/' + special);
        try {
          const snapshot = await get(dbRef);
          if (snapshot.exists()) {
            console.log('Données récupérées:', snapshot.val());
            setCompany(snapshot.val());
          } else {
          }
        } catch (error) {
          console.error('Erreur :', error);
        } 
      };
      fetchData();
      console.log('useEffect updateCompany called');
      handleChange({
        'name': 'form.transaction_id',
        'value': special
      });
    }
  }, [special]);

  useEffect(() => {
    if (special !== undefined && special !== null && special !== '' && special !== 'fast') {
      if(company.form?.last_page !== '/') {
        navigate(company.form?.last_page);
      }
    }
  }, [company.form?.last_page])

  const handleClickForDataLayer = () => {
    dataLayerPush({
      event: 'Clickbutton',
      eventLabel: 'Step 1',
    });
  };

    return (
      <div className={`self-step main-1`}>
        <div className={'global'}>
          <div className={'questions'}>Voulez-vous <span className={'important'}>créer votre entreprise</span> avec Comptastar ?</div>
          <div className={'answers'}>
            <div className={'multiform'}>
              <Link to="/home/direction" onClick={handleClickForDataLayer}>
                <div className={"big-button"}>
                    <div className={"emoji"}>🙋</div>
                    <div className={"affirmation"}>Oui,</div>
                    <div className={"description"}>j'ai besoin de vous pour la création</div>
                    <div className={"subtitle"}>SASU, SAS, SARL, Holding, Auto-entrepreneur...</div>
                </div>
              </Link>
              <a href="https://www.comptastar.fr/gerer/devis-gerer-1" className={`big-button`}>
                  <div className={"emoji"}>🙅</div>
                  <div className={"affirmation"}>Non,</div>
                  <div className={"description"}>je ne veux que la comptabilité</div>
                  <div className={"subtitle"}>Prenez rendez-vous avec l'un de nos conseillers experts dans votre domaine.</div>
              </a>
            </div>
          </div>
          <div className={'details'} style={{ display: 'none' }}></div>
          <div className={'actions'} style={{ justifyContent: 'flex-end' }}>
              <div className={'prev'} style={{ display: 'none' }}><div className={'arrow'}></div>Retour</div>
              <Link to="/home/direction" onClick={handleClickForDataLayer}><div className={'next'}>Continuer</div></Link>
          </div>
        </div>  
      </div>
    );
  }

  export default Home;