import React, { useContext, useEffect, useRef } from 'react';
import { UserContext } from '../../../App';
import { Link } from 'react-router-dom';
import styles from './dealname.module.css';
import { handleFormSubmit } from '../../../utils/contact-informations';
import dataLayerPush from '../../../utils/data-layer';

function DealName() {
    const { company, setCompany, handleChange } = useContext(UserContext);
    const dealNameRef = useRef(null);

    useEffect(() => {
        const inputs = [dealNameRef];
        inputs.forEach(ref => {
            if (ref.current && ref.current.checkValidity()) {
                ref.current.classList.add(styles.active);
            } else {
                ref.current.classList.remove(styles.active);
            }
        });

        if(!company.form.transaction_id) {
            const { email, firstname, lastname, phone } = company.form.contact;
            const { legal_statuts } = company.form;
            handleFormSubmit(email, firstname, lastname, phone, legal_statuts, company, setCompany, handleChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleAllChange(event) {
        handleChange(event);
        if(event.target.classList) {
            if(event.target.checkValidity()) {
                event.target.classList.add(styles.active);
            } else {
                event.target.classList.remove(styles.active);
            }
        }
    }

    const handleClickForDataLayer = () => {
        dataLayerPush({
        event: 'Clickbutton',
        eventLabel: 'Step 6',
        });
    };

    return (
        <div className="self-step main-12">
            <div className="global">
                <div className="questions">Parfait. <br />Connaissez-vous déjà <span className="important">le nom de votre entreprise</span> ?</div>
                <div className="answers">
                    <div className="multiform">
                        <div className={`answers-row ${styles.answers_row}`}>
                            <div className={`answer ${styles.answer}`}>
                                <input type="name" id="form.dealname" name="form.dealname" className="form-login" placeholder="Si oui, saisissez-le ici." value={company.form.dealname} ref={dealNameRef} onChange={handleAllChange}/>
                            </div>
                        </div>
                    </div>
                    {/* <Link onClick={handleClickForDataLayer} to={company.form.fastlane ? '/form/accounting' : '/form/register-your-trademark'}><div className="cta">Continuer</div></Link> */}
                    {/* <Link onClick={handleClickForDataLayer} to={company.form.fastlane ? '/form/accounting' : '/form/register-your-trademark'}><div className="later">Je souhaite répondre plus tard.</div></Link> */}
                    <Link onClick={handleClickForDataLayer} to={company.form.fastlane ? '/form/accounting' : '/form/accounting'}><div className="cta">Continuer</div></Link>
                    <Link onClick={handleClickForDataLayer} to={company.form.fastlane ? '/form/accounting' : '/form/accounting'}><div className="later">Je souhaite répondre plus tard.</div></Link>
                    <div className={`description ${styles.description}`}>Essayez d'y réfléchir, c'est important pour l'image de l'entreprise</div>
                </div>
                <div className="main-details">
                    <div className="details">🌟 Vos clients vous reconnaîtront facilement à travers ce nom de marque. Rassurez-vous, nous pouvons protéger votre entreprise à l'étape suivante</div>
                </div>
                <div className="actions">
                    <Link to={company.form.fastlane ? '/form/contact-informations' : '/form/contact-informations'}><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <div className="next" style={{display: 'none'}}>Continuer</div>
                </div>
            </div>         
        </div>    
    )
}

export default DealName;