import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCD3tmx5IIqo4izAYH5e1jZDKb6YUwqJsY",
  authDomain: "self-onboarding-b16fa.firebaseapp.com",
  projectId: "self-onboarding-b16fa",
  storageBucket: "self-onboarding-b16fa.appspot.com",
  messagingSenderId: "94505197753",
  appId: "1:94505197753:web:54cbe75666d8e2a9d5bb68",
  measurementId: "G-1854YGYYKY",
  databaseURL: "https://self-onboarding-b16fa-default-rtdb.europe-west1.firebasedatabase.app" 
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app); // Obtenez une instance de l'authentification Firebase

export { auth, app, db };