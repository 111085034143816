import React, { useEffect, useRef, useContext, useState } from "react";
import { UserContext } from "../../../App";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from './checkout.module.css';
import payment_recap from '../payment-recap/payment-recap.module.css';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { handleFormSubmit } from '../../../utils/contact-informations';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeForm from './StripeForm.js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
function Checkout() { 
    const { company, setCompany, handleChange, updateCompanyValue } = useContext(UserContext);
    const navigate = useNavigate();
    const { payment_statut, transaction_id } = useParams();
    const prevelement_bancaire_box = useRef(null);
    const almaInformation = useRef(null);
    const sepaInformation = useRef(null);
    const payPalInformation = useRef(null);
    const total_button = useRef(null);
    const total_alma = useRef(null);
    const pop_up = useRef(null);
    const alma_url_p = useRef(null);
    const alma_url_href = useRef(null);
    const alma_url_href_p = useRef(null);
    const oneInstallmentCountSepaButton = useRef(null);
    const connectionForm = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [crmId, setCrmId] = useState('');

    const [sepaInstallmentCount, setSepaInstallmentCount] = useState(null);
    const [sepaInstallmentCountValue, setSepaInstallmentCountValue] = useState(1);
    const [promo_code_pourcentage, set_promo_code_pourcentage] = useState(0);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const [mandateInProgress, setMandateInProgress] = useState(false);
    const [iframeSrc, setIframeSrc] = useState(''); 
    
    const [totalHt, setTotalHt] = useState(0);
    const [totalTva, setTotalTva] = useState(0);
    const [totalTtc, setTotalTtc] = useState(0);
    const [totalPromoCode, setTotalPromoCode] = useState(0);
    const [totalAlma, setTotalAlma] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentChoiceNeeded, setPaymentChoiceNeeded] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if(company.form.transaction_id) {
                // Vérification du positionnement de la transaction, et déplacement si nécessaire
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                
                const raw = JSON.stringify({
                  "deal_stage_obligation": [
                    "267376317",
                    "605682928"
                  ],
                  "transaction_id": company.form.transaction_id,
                  "new_deal_stage": "605670898"
                });
                
                const requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };
                
                try {
                  const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/hubspot/deals/update/new_stage`, requestOptions);
                  const result = await response.text();
                //   console.log(result)
                  return result;
                } catch (error) {
                  console.error(error);
                  return error;
                };
            }
        }
        fetchData();
        setName(company.form.contact.firstname + ' ' + company.form.contact.lastname);
        setEmail(company.form.contact.email);
        setPhone(company.form.contact.phone);
        setCrmId(company.form.transaction_id);
    }, [company.form.transaction_id, company.form.contact.firstname, company.form.contact.lastname, company.form.contact.email, company.form.contact.phone]);

    useEffect(() => {
        set_promo_code_pourcentage(company.form.promo_code_pourcentage);
        // const total = Object.values(company.shoppingCart).reduce((sum, item) => sum + item, 0);
        const total = parseFloat(company.shoppingCart.legal_pack) + parseFloat(company.shoppingCart.court_fees);
        const discount = (total*1.2*company.form.promo_code_pourcentage/100);
        if((total * 1.2) - discount === 0) total_button.current.classList.remove(payment_recap.unselectable);

        if(company.form.transaction_id !== "") {
            if(payment_statut) {
                switch (payment_statut) {
                    case 'cancelled':
                        // console.log('Paiement Alma annulé');
                        break;
                    case 'failed':
                        prevelement_bancaire_box.current.style.border = '1px solid var(--gold)';
                        prevelement_bancaire_box.current.style.boxShadow = '0 0px 9px 1px #FFDF00';
                        break;
                    default:
                        break;
                }
            }
        }
    }, [company.form.promo_code_pourcentage, payment_statut, company.form.transaction_id])

    useEffect(() => {
        if(company.form.transaction_id !== "") {
            // const total = Object.values(company.shoppingCart).reduce((sum, item) => {return (item !== true) ? sum + item : sum;}, 0);
            const total = 0;
            setTotalHt(parseFloat(total.toFixed(2)).toLocaleString('fr-FR').replace('.', ',') + '€');
            setTotalTva((total * 0.2).toFixed(2).replace('.', ',') + '€');
            const discount = (total*1.2*promo_code_pourcentage/100);
            if(parseInt(company.form.promo_code_pourcentage) > 0) { 
                setTotalPromoCode("- " + discount.toFixed(2).replace('.', ',') + '€');
            };
            setTotalTtc(parseFloat(((total * 1.2) - discount).toFixed(2)).toLocaleString('fr-FR').replace('.', ',') + '€');
            setTotalAlma((((total * 1.2) - discount)/4).toFixed(2).replace('.', ','));
        }
    }, [company.shoppingCart, promo_code_pourcentage, company.form.transaction_id]); // Dépendance à shoppingCart pour recalculer à chaque changement

    function pop_up_closed() {
        pop_up.current.style.display = 'none';
    }

    const pay_action = async (event) => {
        // const total = Object.values(company.shoppingCart).reduce((sum, item) => {return (item !== true) ? sum + item : sum;}, 0);
        const total = 0;
        
        const discount = (total*1.2*promo_code_pourcentage/100);
        const total_ttc_value = parseFloat(((total * 1.2) - discount).toFixed(2));
        // var myHeaders, raw, requestOptions;
        setIsLoading(true);
        setPaymentChoiceNeeded(false);
        // if(total_ttc_value > 0) {
            console.log(`Paiement nécessaire via ${selectedOption} de : `, total_ttc_value );
            switch (selectedOption) {
                // case 'cb':
                //     myHeaders = new Headers();
                //     myHeaders.append("Content-Type", "application/json");
                    
                //     raw = JSON.stringify({
                //         "hubspot_id": company.form.transaction_id,
                //         "purchase_amount": total_ttc_value * 100,
                //         "installments_count": null,
                //         "cancel_url": `${process.env.REACT_APP_FRONTEND_DOMAIN}form/checkout/cancelled/${company.form.transaction_id}`,
                //         "webhook_url": `${process.env.REACT_APP_FRONTEND_DOMAIN}form/payment-success/alma`,
                //         "success_url": `${process.env.REACT_APP_FRONTEND_DOMAIN}form/payment-success/alma`,
                //         "failed_url": `${process.env.REACT_APP_FRONTEND_DOMAIN}form/checkout/failed/${company.form.transaction_id}`
                //         });
                    
                //     requestOptions = {
                //       method: "POST",
                //       headers: myHeaders,
                //       body: raw,
                //       redirect: "follow"
                //     };
                    
                //     try {
                //         const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/payment/alma`, requestOptions);
                //         const result = await response.json();
                //         console.log(result);
                //         const url = result.response.url;
                //         window.open(url, '_blank').focus();
                //         setIsLoading(false);
                //         alma_url_p.current.innerHTML = url;
                //         alma_url_href_p.current.setAttribute('href', url);
                //         pop_up.current.style.display = 'flex';
                //         alma_url_href.current.setAttribute('href', url);      
                //     } catch (error) {
                //       console.error(error);
                //     };
                //     break;
                // case 'paypal':

                //     break;
                // case 'alma':
                //     myHeaders = new Headers();
                //     myHeaders.append("Content-Type", "application/json");
                    
                //     raw = JSON.stringify({
                //       "hubspot_id": company.form.transaction_id,
                //       "purchase_amount": total_ttc_value * 100,
                //       "installments_count": null,
                //       "cancel_url": `${process.env.REACT_APP_FRONTEND_DOMAIN}form/checkout/cancelled/${company.form.transaction_id}`,
                //       "webhook_url": `${process.env.REACT_APP_FRONTEND_DOMAIN}form/payment-success/alma`,
                //       "success_url": `${process.env.REACT_APP_FRONTEND_DOMAIN}form/payment-success/alma`,
                //       "failed_url": `${process.env.REACT_APP_FRONTEND_DOMAIN}form/checkout/failed/${company.form.transaction_id}`
                //       });
                    
                //     requestOptions = {
                //       method: "POST",
                //       headers: myHeaders,
                //       body: raw,
                //       redirect: "follow"
                //     };
                    
                //     try {
                //       const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/payment/alma`, requestOptions);
                //       const result = await response.json();
                //       const url = result.response.url;
                //       window.open(url, '_blank').focus();
                //       setIsLoading(false);
                //       alma_url_p.current.innerHTML = url;
                //       alma_url_href_p.current.setAttribute('href', url);
                //       pop_up.current.style.display = 'flex';
                //       alma_url_href.current.setAttribute('href', url);   
                //     } catch (error) {
                //       console.error(error);
                //     };
                //     break;
                case 'prelevement_bancaire':
                    const installment_count = sepaInstallmentCountValue;
                    if (company && company.sepa && company.sepa.billing_request_id !== undefined && company.sepa.billing_request_id) {
                        const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        const raw = JSON.stringify({
                            "transaction_id": company.form.transaction_id,
                            "billing_request_id": company.sepa.billing_request_id,
                            "installment_count": installment_count.toString()
                        });
                        const requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: raw,
                            redirect: "follow"
                        };
                        try {
                            const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/gocardless/billing_request_flow/create`, requestOptions);
                            const result = await response.json();
                            setMandateInProgress(true);
                            const src = result.brf_url;
                            setIframeSrc(src);
                            setIsLoading(false);
                        } catch (error) {
                            setIsLoading(false);
                            console.error(error);
                        };
                        break;
                    } else {
                        const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        const raw = JSON.stringify({
                            "objectId": company.form.transaction_id,
                            "installment_count": installment_count.toString()
                        });
                        const billingRequestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: raw,
                            redirect: "follow"
                        };
                        try {
                            const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/gocardless/mandate/create`, billingRequestOptions);
                            const result = await response.json();
                            setCompany({...company, sepa: { ...company.sepa, billing_request_id: result.billingRequest.br_id }});
                            console.log(result);
                            const billingRequestFlowHeaders = new Headers();
                            billingRequestFlowHeaders.append("Content-Type", "application/json");
                            const billingRequestFlowRaw = JSON.stringify({
                                "transaction_id": company.form.transaction_id,
                                "billing_request_id": result.billingRequest.br_id,
                                "installment_count": installment_count.toString()
                            });
                            const billingRequestFlowsOptions = {
                                method: "POST",
                                headers: billingRequestFlowHeaders,
                                body: billingRequestFlowRaw,
                                redirect: "follow"
                            };
                            try {
                                const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/gocardless/billing_request_flow/create`, billingRequestFlowsOptions);
                                const result = await response.json();
                                setMandateInProgress(true);
                                setIsLoading(false);
                                const src = result.brf_url;
                                setIframeSrc(src);
                            } catch (error) {
                                setIsLoading(false);
                                console.error(error);
                            };
                        } catch (error) {
                            console.error(error);
                        }
                    }


                    // navigate(`/form/success?payment_solution=sepa&installment_count=${installmentCount}`);
                    // console.log(installmentCount);
                    break;
                default:
                    setIsLoading(false);
                    setPaymentChoiceNeeded(true);
                    console.error('Aucune mode de paiement sélectionné', isLoading, paymentChoiceNeeded);
                    break;
            }
        // } else {
        //     navigate('/form/success');
        // }
    }

    const paymentMethodChange = (event) => {
        setSelectedOption(event.target.id);
        // console.log('Selected option :', event.target.id);
        if(event.target.id) {
            switch (event.target.id) {
                // case 'alma':
                //     almaInformation.current.style.display = 'block';
                //     sepaInformation.current.style.display = 'none';
                //     payPalInformation.current.style.display = 'none';
                //     total_button.current.classList.remove(payment_recap.unselectable);
                //     break;
                // case 'cb':
                //     almaInformation.current.style.display = 'none';
                //     sepaInformation.current.style.display = 'none';
                //     payPalInformation.current.style.display = 'none';
                //     total_button.current.classList.remove(payment_recap.unselectable);
                //     break;
                case 'prelevement_bancaire':
                    // almaInformation.current.style.display = 'none';
                    if(payment_statut === 'failed') {
                        sepaInformation.current.style.display = 'flex';
                    }
                    // payPalInformation.current.style.display = 'none';
                    total_button.current.classList.remove(payment_recap.unselectable);
                    break;
                // case 'paypal':
                //     almaInformation.current.style.display = 'none';
                //     sepaInformation.current.style.display = 'none';
                //     payPalInformation.current.style.display = 'block';
                //     total_button.current.classList.add(payment_recap.unselectable);
                //     break;
                default:
                    break;
            }
        }
    }

    const sepaPayment = (event, installmentCount) => {
        if(sepaInstallmentCount) {
            sepaInstallmentCount.classList.remove(styles.active);
            sepaInstallmentCount.classList.add(styles.inactive);
        } else {
            oneInstallmentCountSepaButton.current.classList.remove(styles.active);
            oneInstallmentCountSepaButton.current.classList.add(styles.inactive);
        }
        // console.log(event);
        event.target.classList.add(styles.active);
        setSepaInstallmentCount(event.target);
        setSepaInstallmentCountValue(installmentCount);
        // console.log(sepaInstallmentCount);
    }

    // PayPal
    const handleError = (err) => {
        console.error('PayPal Checkout Error', err);
        // Handle the error
        navigate(`/form/checkout/failed/${company.form.transaction_id}`);
    };

    const contactFormSent = (event) => {
        if(connectionForm.current.checkValidity()) {
            const { email, firstname, lastname, phone } = company.form.contact;
            const { legal_statuts } = company.form;
            // console.log(email, firstname, lastname, phone, legal_statuts);
            // console.log(connectionForm.current.checkValidity(), firstNameRef.current.value, lastNameRef.current.value, emailRef.current.value, phoneRef.current.value);
            handleFormSubmit(email, firstname, lastname, phone, legal_statuts, company, setCompany, updateCompanyValue);
        }
    }

    function handleAllChange(event) {
        handleChange(event);
        if(event.target.classList) {
            if(event.target.checkValidity()) {
                event.target.classList.add(styles.active);
            } else {
                event.target.classList.remove(styles.active);
            }
        }
    }

    return (
        <div className="self-step main-23">
            <div className="global">
                {
                    isLoading && (
                        <div className={styles.loader}>
                            <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_l9bcfk19.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop  autoplay></lottie-player>
                        </div>
                    )
                }
                <div className={styles.pop_up} ref={pop_up}>
                    <div className={`${styles.cross} ${styles.close}`} onClick={pop_up_closed}>Fermer</div>
                    <div className={styles.main_pop_up}>
                        <p>Si un nouvel onglet ne s'est pas automatiquement ouvert, veuillez cliquer sur le lien ci-dessous ou copier l'URL dans votre navigateur :</p>
                        <a ref={alma_url_href_p} href="#" target="_blank"><p ref={alma_url_p}>https://app.alma.com/</p></a>
                        <a ref={alma_url_href} href="https://app.alma.com/payment_sbfweniwnbfof1234" target="_blank">
                            <div className={`cta`}>
                                Aller sur Alma
                            </div>
                        </a>
                    </div>
                </div>
                {
                    !mandateInProgress ? (
                        <>
                            <div className="questions">Récapitulatif de votre commande (2/2)</div>
                            <div className={styles.subtitle}>
                                Dernière étape ! Suite au paiement, vous accèderez aux services et serez mis en relation avec votre expert dédié.
                            </div>
                                {
                                    company.form.transaction_id === "" ? (
                                        <div className={styles.content}>
                                            <div className={styles['description']}>
                                                <form id="connection-form" ref={connectionForm}>
                                                    <div className={`connection ${styles.connection}`}>
                                                        <div className={`answers-row ${styles["answers-row"]} ${styles.name_input}`}>
                                                            <div className={`answer ${styles.answer}`}>
                                                                <label className={`label ${styles.label}`} htmlFor="firstname">Votre prénom</label>
                                                                <input type="text" id="form.contact.firstname" name="form.contact.firstname" className={styles["form-login"]} placeholder="Jonh" value={company.form.contact.firstname} onChange={handleAllChange} ref={firstNameRef} required/>
                                                            </div>
                                                            <div className={`answer ${styles.answer}`}>
                                                                <label className={`label ${styles.label}`} htmlFor="lastname">Votre nom</label>
                                                                <input type="text" id="form.contact.lastname" name="form.contact.lastname" className={styles["form-login"]} placeholder="Doe" value={company.form.contact.lastname} onChange={handleAllChange} ref={lastNameRef} required/>
                                                            </div>
                                                        </div>
                                                        <div className={`answers-row ${styles["answers-row"]} ${styles.coords}`}>
                                                            <div className={`answer ${styles.answer}`}>
                                                                <label className={`label ${styles.label}`} htmlFor="mail">Votre e-mail <span className={styles.required}>*</span></label>
                                                                <input type="email" id="form.contact.email" name="form.contact.email" className={styles["form-login"]} placeholder="jonh@comptastar.fr" value={company.form.contact.email} onChange={handleAllChange} ref={emailRef} required/>
                                                            </div>
                                                            <div className={`answer ${styles.answer}`}>
                                                                <label className={`label ${styles.label}`} htmlFor="mobile">Votre numéro de téléphone <span className={styles.required}>*</span></label>
                                                                <input type="tel" id="form.contact.phone" name="form.contact.phone" className={styles["form-login"]} placeholder="+33 6 07 08 09 10" value={company.form.contact.phone} onChange={handleAllChange} ref={phoneRef} required/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`cta ${styles.cta}`} onClick={(event) => contactFormSent(event)}>Continuer</div>
                                                </form>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles['merchant-recap']}>
                                            <div className={styles.content}>
                                                {
                                                    payment_statut === 'failed' ? (
                                                        <div className={`${styles['description']} ${styles.error}`}>
                                                            <div className={styles.title}>
                                                                <div className={styles.name}>Paiement échoué</div>
                                                            </div>
                                                            <div>
                                                                Veuillez essayer un autre moyen de paiement. Vous avez également la possibilité de régler par prélèvement bancaire en <span className={styles.bold}>quatre fois sans frais</span> depuis votre compte personnel.
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <div className={styles['description']}>
                                                    <div className={styles.title}>
                                                        <div className={styles.main_name}>Avantages et Services compris</div>
                                                    </div>
                                                    <div className={styles.key_point}>
                                                        <ul>
                                                            <li><span className={styles.bold}>Création gratuite de votre entreprise</span> recevez rapidement vos statuts, KBIS et SIREN</li>
                                                            <li><span className={styles.bold}>Gestion comptable de A à Z</span> (comptes annuels, accès illimités au logiciel)</li>
                                                            <li><span className={styles.bold}>Conseils illimités</span> en fiscalité, juridique et social (sur votre secteur d'activité)</li>
                                                            <li><span className={styles.bold}>Comptable dédié</span> disponible 5j/7</li>
                                                        </ul>
                                                    </div>
                                                </div>  
                                                <div className={styles['line-items']}>
                                                    {/* <div className={styles.title}>
                                                        <div className={styles.main_name}>Renseignez vos informations bancaires</div>
                                                    </div> */}
                                                    <div className={styles['line-item']}>
                                                        {/* <div className={`${styles.security} ${styles.choice}`}><img alt="icon lock" src="/icon/lock.svg"/> Paiement sécurisé</div> */}
                                                    <div className={styles.payment_solutions}>
                                                    {/* <Elements stripe={stripePromise}>
                                                        <StripeForm name={name} email={email} phone={phone} crmId={crmId} company={company} setName={setName} setEmail={setEmail} setPhone={setPhone} />
                                                    </Elements> */}
                                                        {/* <div className={styles.payment_solution}>
                                                            <label className={styles.label} htmlFor="cb">
                                                                <div className={styles.text}>
                                                                    <input type="radio" name="input-choice" id="cb" checked={selectedOption === 'cb'} onChange={paymentMethodChange}/>
                                                                    Carte bancaire
                                                                </div>
                                                                <div className={styles.logo}><img alt="Logo carte bancaire" src="/payment_solutions/creditcards.png"/></div>
                                                            </label>
                                                        </div>
                                                        <div className={styles.payment_solution}>
                                                            <label className={`${styles.label} ${styles.paypalChoice}`} htmlFor="paypal">
                                                                <div className={styles.payment_choice}>
                                                                    <div className={styles.text}>
                                                                        <input type="radio" name="input-choice" id="paypal" checked={selectedOption === 'paypal'} onChange={paymentMethodChange}/>
                                                                        PayPal : Paiement en 1 à 4 fois
                                                                    </div>
                                                                    <div className={styles.logo}><img alt="Logo PayPal" src="/payment_solutions/paypal.png"/></div>
                                                                </div>
                                                                <div className={`${styles.listSepa} ${styles.paypalChoice}`} ref={payPalInformation}>
                                                                    <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_ID, components: "buttons" }}>
                                                                        <div className={styles.paypalButton}>
                                                                            <PayPalButtons
                                                                            createOrder={(data, actions) => {
                                                                                return actions.order.create({
                                                                                purchase_units: [
                                                                                    {
                                                                                    amount: {
                                                                                        value: "10.00", // Set the amount here
                                                                                    },
                                                                                    },
                                                                                ],
                                                                                });
                                                                            }}
                                                                            onApprove={async (data, actions) => {
                                                                                try {
                                                                                    const details = await actions.order.capture();
                                                                                    if (details.status === "COMPLETED") {
                                                                                        setCompany({...company, payment: { ...company.payments, payment_solution: 'paypal', payment_id: details.id }});
                                                                                        console.log("Payment captured successfully", details);
                                                                                        navigate(`/form/payment-success/paypal?pid=${details.id}`);
                                                                                    } else {
                                                                                        // Gérer le cas où le statut n'est pas COMPLETED
                                                                                        console.error("Payment did not complete", details);
                                                                                        navigate(`/form/checkout/failed/${company.form.transaction_id}`);
                                                                                    }
                                                                                } catch (error) {
                                                                                    console.error("Failed to capture payment", error);
                                                                                    navigate(`/form/checkout/failed/${company.form.transaction_id}`);
                                                                                }
                                                                            }}
                                                                            onError={(err) => {
                                                                                handleError(err);
                                                                            }}
                                                                            onCancel={(data) => {
                                                                                console.log('Payment cancelled', data);
                                                                                navigate(`/form/checkout/cancelled/${company.form.transaction_id}`);
                                                                            }}
                                                                            />
                                                                        </div>
                                                                    </PayPalScriptProvider>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className={styles.payment_solution}>
                                                            <label className={`${styles.label} ${styles.almaChoice}`} htmlFor="alma">
                                                                <div className={styles.payment_choice}>
                                                                    <div className={styles.text}>
                                                                        <input type="radio" name="input-choice" id="alma" checked={selectedOption === 'alma'} onChange={paymentMethodChange}/>
                                                                        Alma : Paiement en 3 ou 4 fois sans frais par carte bancaire
                                                                    </div>
                                                                    <div className={styles.logo}><img alt="Logo Alma" src="/payment_solutions/alma.png"/></div>
                                                                </div>
                                                                <div className={`${styles.listSepa} ${styles.almaChoice}`} ref={almaInformation}>
                                                                    <div className={styles.information}>
                                                                        ⚠️ Les cartes suivantes ne peuvent pas être utilisées pour effectuer des paiements en plusieurs fois :<br />
                                                                        <ul>
                                                                            <li>Carte numérique, comme N26, Revolut, etc.</li>
                                                                            <li>Cartes émises par des banques étrangères.</li>
                                                                            <li>Cartes prépayées, telles que les cartes Correos, MoneyToPay, Spark, etc.</li>
                                                                        </ul>
                                                                        Toutefois, vous pouvez les utiliser pour réaliser un paiement en une seule fois.
                                                                    </div>
                                                                </div>
                                                            </label>
                                                            <div id="alma-form-container"></div>
                                                        </div> */}
                                                        <div className={styles.payment_solution}>
                                                            <label className={`${styles.label} ${styles.sepaChoice}`} htmlFor="prelevement_bancaire" ref={prevelement_bancaire_box}>
                                                                {payment_statut === "failed" ? (
                                                                    <div className={styles.payment_solution_spotlight}>Payer en 4 fois sans frais via prélèvement bancaire</div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                <div className={styles.payment_choice}>
                                                                    <div className={styles.text}>
                                                                        <input type="radio" name="input-choice" id="prelevement_bancaire" checked={selectedOption === 'prelevement_bancaire'} onChange={paymentMethodChange}/>
                                                                        Prélèvement bancaire{/*{payment_statut === 'failed' ? '' : 'en 1 fois'}*/}
                                                                    </div>
                                                                    <div className={styles.logo}><img alt="Logo prélèvement bancaire" src="/payment_solutions/sepa.svg"/></div>
                                                                </div>
                                                                <div className={`${styles.listSepa} ${styles.sepaChoice}`} ref={sepaInformation}>
                                                                    <div className={styles.mainSepaChoice}>
                                                                        <div className={`${styles.cta} ${styles.sepaSubChoice} ${styles.active}`} ref={oneInstallmentCountSepaButton} onClick={(event) => sepaPayment(event, 1)}>
                                                                            <div>En 1 fois</div>
                                                                            <div className={styles.detailFees}>Sans frais</div>
                                                                        </div>
                                                                        <div className={`${styles.cta} ${styles.sepaSubChoice} ${styles.inactive}`} onClick={(event) => sepaPayment(event, 2)}>
                                                                            <div>En 2 fois sur 2 mois</div>
                                                                            <div className={styles.detailFees}>Sans frais</div>
                                                                        </div>
                                                                        <div className={`${styles.cta} ${styles.sepaSubChoice} ${styles.inactive}`} onClick={(event) => sepaPayment(event, 3)}>
                                                                            <div>En 3 fois sur 3 mois</div>
                                                                            <div className={styles.detailFees}>Sans frais</div>
                                                                        </div>
                                                                        <div className={`${styles.cta} ${styles.sepaSubChoice} ${styles.inactive}`} onClick={(event) => sepaPayment(event, 4)}>
                                                                            <div>En 4 fois sur 4 mois</div>
                                                                            <div className={styles.detailFees}>Sans frais</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div className={payment_recap.complementary}>
                                                <div className={payment_recap.pricing}>
                                                    <div className={`${payment_recap.title} ${payment_recap.title_recap_1}`}>Récapitulatif</div>
                                                    <div className={payment_recap.ht_total}>
                                                        <div className={payment_recap.label}>Sous-total HT :</div>
                                                        <div className={payment_recap.price}>{totalHt}</div>
                                                    </div>
                                                    <div className={payment_recap.tva_total}>
                                                        <div className={payment_recap.label}>TVA :<div className="question overlay">?<span className="answer"><span style={{textDecoration: 'underline'}}>Votre société pourra récupérer la TVA facturée sur les frais de création.</span></span></div></div>
                                                        <div className={payment_recap.price}>{totalTva}</div>
                                                    </div>
                                                    {
                                                        parseInt(company.form.promo_code_pourcentage) > 0 && <div className={payment_recap.promo_total}><div className={payment_recap.label}>Remise :</div><div className={payment_recap.price}>{totalPromoCode}</div></div>
                                                    }
                                                    <div className={payment_recap.total}>
                                                        <div className={payment_recap.label}>Total TTC :</div>
                                                        <div className={payment_recap.price}>{totalTtc}</div>
                                                    </div>
                                                    {
                                                        paymentChoiceNeeded && <div className={payment_recap.paymentChoiceNeeded}>Veuillez choisir un mode de paiement</div>
                                                    }
                                                    <div className={`cta ${payment_recap.cta} ${isLoading && (payment_recap.unselectable)} ${isLoading}`} ref={total_button} onClick={pay_action}>{isLoading ? (<>Chargement...</>) : (<>Remplir mon RIB</>)}</div>
                                                    {/* <div className={payment_recap.alma}>
                                                        <div className={payment_recap.label}>ou <span id="alma_pricing" style={{marginLeft: '3px'}}>{totalAlma}</span>€ avec <img alt="icon alma" src="/icon/alma.png"/><div className={payment_recap.x4}><div>x4</div></div></div>
                                                    </div> */}
                                                    {/* <div className={payment_recap.security}><img alt="icon lock" src="/icon/lock.svg"/> Paiement sécurisé</div> */}
                                                    {/* <div className={payment_recap.reinsurance}>
                                                        <div className={payment_recap.important}>0 € de votre poche</div>
                                                        <div className={payment_recap.description}>Passez l'intégralité de cette dépense comme charge de votre entreprise grâce à votre comptable.</div>
                                                    </div> */}
                                                </div>
                                                <div className={payment_recap.contact}>
                                                    <div className={payment_recap.title}>Une question sur votre commande ?</div>
                                                    <div className={payment_recap.description}>Contactez notre équipe disponible de 9h à 19h.</div>
                                                    <div className={payment_recap.telephone}>
                                                        <div className={payment_recap.number}>
                                                            <img className={`${payment_recap.icon} ${payment_recap.telephone}`} alt="icon telephone" src="/icon/telephone.svg"/> <Link to="tel:+33745898101">07 45 89 81 01</Link>
                                                        </div>
                                                        <div className={payment_recap.free}>Gratuit</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                        </>
                    ) : (
                        <>
                            <div className="questions">Signature du mandat SEPA (2/2)</div>
                            <div className={styles.subtitle}>
                                {/* Vous pouvez utiliser votre RIB personnel ou professionnel ; votre comptable se chargera d'enregistrer les frais comme des charges comptables. */}
                                Rassurez-vous, ce mandat de prélèvement vous est demandé pour les prélèvements futurs de votre abonnement comptable
                            </div>
                            <div className={`${styles['merchant-recap']} ${styles.iframeMain}`}>
                                <div className={`${styles.content} ${styles.mandateBox}`}>
                                    <iframe 
                                        src={iframeSrc} 
                                        title="Mandat SEPA"
                                        width="100%"
                                        height="auto"   
                                    />
                                    <div className="actions">
                                        <div className="prev" onClick={() => setMandateInProgress(false)}><div className="arrow"></div>Retour</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
                <div className="actions">
                    <Link to='/form/merchant-recap' style={{display: 'none'}}><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <div className="next" style={{display: 'none'}}>Continuer</div>
                </div>
            </div>
        </div>
    )
}

export default Checkout;