import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';

function Charges() {
    const { handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            navigate('/tax-assistant/result');
        }
    }
    return (
        <div className="self-step main-6">
            <div className="global">
                <div className="questions">Dernière question, quel est le <span className="important">montant de charges</span> que votre entreprise assumera ?</div>
                <div className="answers">
                    <div className="multiform" question='assistant.charges'>
                        <div className="big-button" onClick={handleSubmit} choice="- de 34%">
                            <div className="emoji">📉</div>
                            <div className="affirmation">Peu de charges.</div>
                            <div className="description"></div>
                            <div className="subtitle">Juste un peu de matériel, quelques frais de déplacement et factures.</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="+ de 34%">
                            <div className="emoji">📈</div>
                            <div className="affirmation">Beaucoup de charges !</div>
                            <div className="description"></div>
                            <div className="subtitle">Je pense plus de la moitié du chiffre d'affaires avec de nombreux frais.</div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <Link to='/tax-assistant/ca-estimation'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link to='/tax-assistant/result'><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}
export default Charges;