import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './legal-pack.module.css';
import dataLayerPush from '../../../utils/data-layer';

function LegalPack() {
    const { handleChange, company } = useContext(UserContext);
    const [creationFees, setCreationFees] = useState(() => {
        return 0;
    });
    const [legalPack1, setLegalPack1] = useState(229);
    const [legalPack2, setLegalPack2] = useState(169);
    const [legalPack3, setLegalPack3] = useState(0);

    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest(`.big-button, .cta, .later, .next, .${styles['offer-box']}`);
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            handleClickForDataLayer();
            navigate('/form/merchant-recap');
        }
    }
    useEffect(() => {
        setCreationFees(String(company.shoppingCart?.court_fees).replace(/\./g, ','));
    }, [company.form.legal_statuts]);

    useEffect(() => {
        if(company.form.fastlane) {
            setLegalPack1(149);
            setLegalPack2(99);
            setLegalPack3(0);
        } else {
            setLegalPack1(229);
            setLegalPack2(169);
            setLegalPack3(0);
        }
    }, [company.form.fastlane]);

    const handleClickForDataLayer = () => {
        const total = Object.values(company.shoppingCart).reduce((sum, item) => {return (item !== true) ? sum + item : sum;}, 0);
        dataLayerPush({
            event: 'add_to_cart',
            price: (total * 1.2).toFixed(2),
        });
    };

    return (
        <div className={`self-step ${styles['self-step']} ${styles['main-21']}`}>
            <div className={`global ${styles.global}`}>
                <div className="questions">Choisissez la formule qui vous correspond le mieux :</div>
                <div className={styles.offer}>
                    <div className={`${styles['offer-main']}`} style={{zIndex: 2}} question='form.legal_pack'>
                        <div className={`${styles['offer-box']}`} onClick={handleSubmit} choice='Premium & Réussite'>
                            <div className={`${styles.reinsurance} ${styles.important}`}>Choisie par 8 personnes sur 10</div>
                            <div className={styles.title}>Premium & Réussite</div>
                            <div className={styles.price}>{legalPack1}<span className={styles.space}> </span>€<span className={styles.space_ht}> </span><span className={styles.exhibitor}>HT</span></div>
                            <div className={styles.subprice}>0€ de votre poche</div>
                            <div className={`${styles['question-box']} question-box`}><div className="question overlay">?<span className="answer"><span style={{textDecoration: 'underline'}}>Remboursement de la création de SASU : {creationFees} € TTC</span> <br /><br />Votre entreprise peut vous rembourser ce montant sur note de frais. Éditez une note de frais à votre nom, en indiquant le montant que vous avez dépensé pour la création de votre entreprise. Votre comptable dédié Comptastar saisira ensuite cette facture comme une charge ! Vous pourrez ensuite effectuer un virement depuis le compte professionnel vers votre compte personnel.</span></div></div>
                            <div className={styles.description}>+ {creationFees} € de frais de greffe et de publication légale <div className={`${styles['question-box']} question-box`}><div className="question overlay">?<span className="answer"><span style={{textDecoration: 'underline'}}>Ces frais sont obligatoires et imposés par l'État Français.</span></span></div></div></div>
                            <ul>
                                <li>Préparation de vos statuts et envoi au greffe.</li>
                                <li>Garanti anti rejet du Greffe.</li>
                                <li>Réponse à toutes vos questions par un juriste en droit des sociétés et entreprise, basé en France.</li>
                                <li>Obtention accélérée de votre KBIS et SIREN.</li>
                                <li>Accompagnement illimité par téléphone avec votre juriste dédié.</li>
                                <li>Vérification de votre dossier et obtention des projets de statuts en 24h.</li>
                                <li>Statuts juridiques sur-mesure avec des conseils et modifications illimités.</li>
                            </ul>
                        </div>
                        <div className="cta" onClick={handleSubmit} choice='Premium & Réussite'>Choisir le pack premium</div>
                    </div>
                    <div className={`${styles['offer-main']}`} style={{zIndex: 1}} question='form.legal_pack'>
                        <div className={styles['offer-box']} onClick={handleSubmit} choice='Standard'>
                            <div className={styles.title}>Standard</div>
                            <div className={styles.price}>{legalPack2}<span className={styles.space}> </span>€<span className={styles.space_ht}> </span><span className={styles.exhibitor}>HT</span></div>
                            <div className={styles.subprice}>0€ de votre poche</div>
                            <div className={`${styles['question-box']} question-box`}><div className="question overlay">?<span className="answer"><span style={{textDecoration: 'underline'}}>Remboursement de la création de SASU : {creationFees} € TTC</span> <br /><br />Votre entreprise peut vous rembourser ce montant sur note de frais. Éditez une note de frais à votre nom, en indiquant le montant que vous avez dépensé pour la création de votre entreprise. Votre comptable dédié Comptastar saisira ensuite cette facture comme une charge ! Vous pourrez ensuite effectuer un virement depuis le compte professionnel vers votre compte personnel.</span></div></div>
                            <div className={styles.description}>+ {creationFees} € de frais de greffe et de publication légale <div className={`${styles['question-box']} question-box`}><div className="question overlay">?<span className="answer"><span style={{textDecoration: 'underline'}}>Ces frais sont obligatoires et imposés par l'État Français.</span></span></div></div></div>
                            <ul>
                                <li>Préparation de vos statuts et envoi au greffe.</li>
                                <li>Garanti anti rejet du Greffe.</li>
                                <li>Réponse à toutes vos questions par un juriste en droit des sociétés et entreprise, basé en France.</li>
                                <li>Obtention accélérée de votre KBIS et SIREN.</li>
                                <li className={styles.not}>Accompagnement illimité par téléphone avec votre juriste dédié.</li>
                                <li className={styles.not}>Vérification de votre dossier et obtention des projets de statuts en 24h.</li>
                                <li className={styles.not}>Statuts juridiques sur-mesure avec des conseils et modifications illimités.</li>
                            </ul>
                        </div>
                        <div className={`cta ${styles.cta}`} onClick={handleSubmit} choice='Standard'>Choisir le pack standard</div>
                    </div>
                    <div className={`${styles['offer-main']}`} style={{zIndex: 0}} question='form.legal_pack'>
                        <div className={styles['offer-box']} onClick={handleSubmit} choice='Débutant & Seul'>
                            <div className={styles.title}>Débutant & Seul</div>
                            <div className={styles.price}>{legalPack3}<span className={styles.space}> </span>€<span className={styles.space_ht}> </span><span className={styles.exhibitor}>HT</span></div>
                            <div className={styles.description} style={{marginTop: '20px'}}>+ {creationFees} € de frais de greffe et de publication légale <div className={`${styles['question-box']} question-box`}><div className="question overlay">?<span className="answer"><span style={{textDecoration: 'underline'}}>Ces frais sont obligatoires et imposés par l'État Français.</span></span></div></div></div>
                            <div className={`${styles['question-box']} question-box`} style={{marginBottom: '0'}}></div>
                            <div className={styles.subprice}></div>
                            <ul>
                                <li>Préparation de vos statuts et envoi au greffe.</li>
                                <li className={styles.not}>Garanti anti rejet du Greffe.</li>
                                <li className={styles.not}>Réponse à toutes vos questions par un juriste en droit des sociétés et entreprise, basé en France.</li>
                                <li className={styles.not}>Obtention accélérée de votre KBIS et SIREN.</li>
                                <li className={styles.not}>Accompagnement illimité par téléphone avec votre juriste dédié.</li>
                                <li className={styles.not}>Vérification de votre dossier et obtention des projets de statuts en 24h.</li>
                                <li className={styles.not}>Statuts juridiques sur-mesure avec des conseils et modifications illimités.</li>
                            </ul>
                        </div>
                        <div className={`cta ${styles.cta}`} onClick={handleSubmit} choice='Débutant & Seul'>Choisir le pack débutant</div>
                    </div>

                </div>
                <div className={`${styles.questions} questions ${styles['second-title']}`}>Détail des offres</div>
                <div className={styles['detail-offer']}>
                    <table className={styles.offer_table}>
                        <colgroup>
                            <col></col>
                            <col className={styles['pre-choice']}></col>
                            <col></col>
                            <col></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th></th>
                                <th className={styles['pre-choice']} question='form.legal_pack'>
                                    <div className={styles.title}>Premium & Réussite</div>
                                    <div className={styles.description}>Je lance mon entreprise de la meilleure des manières.</div>
                                    <div className={styles.price}>{legalPack1}€ <span className={styles.little_exhibitor}>HT</span></div>
                                    <div className={`cta ${styles.cta}`} onClick={handleSubmit} choice='Premium & Réussite'>Choisir ce pack</div>
                                </th>
                                <th question='form.legal_pack'>
                                    <div className={styles.title}>Standard</div>
                                    <div className={styles.description}>Je délègue en partie les tâches administratives.</div>
                                    <div className={styles.price}>{legalPack2}€ <span className={styles.little_exhibitor}>HT</span></div>
                                    <div className={`cta ${styles.cta} ${styles['not-pre-choice']}`} onClick={handleSubmit} choice='Standard'>Choisir ce pack</div>
                                </th>
                                <th question='form.legal_pack'>
                                    <div className={styles.title}>Débutant & Seul</div>
                                    <div className={styles.description}>Je gère seul les démarches administratives et assume les responsabilités.</div>
                                    <div className={styles.price}>{legalPack3}€ <span className={styles.little_exhibitor}>HT</span></div>
                                    <div className={`cta ${styles.cta} ${styles['not-pre-choice']}`} onClick={handleSubmit} choice='Débutant & Seul'>Choisir ce pack</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p className={styles.title}>Traitement prioritaire</p>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Traitement de votre dossier</p>
                                </td>
                                <td>
                                    <p>Sous une semaine</p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>Sous une semaine</p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>Sous une semaine</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Garantie anti-rejet</p>
                                </td>
                                <td>
                                    <p>Inclus</p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Accompagnement par un juriste</p>
                                </td>
                                <td>
                                    <p>Par email et par téléphone</p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>Uniquement par email</p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>Uniquement par email</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className={styles.title}>Accompagnement</p>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Préparation de vos statuts et envoi au Greffe.</p>
                                </td>
                                <td>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Garanti anti rejet du Greffe.</p>
                                </td>
                                <td>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Réponse à toutes vos questions par un juriste en droit des sociétés et entreprise, basé en France.</p>
                                </td>
                                <td>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Obtention accélérée de votre KBIS et SIREN.</p>
                                </td>
                                <td>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Accompagnement illimité par téléphone avec votre juriste dédié.</p>
                                </td>
                                <td>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Vérification de votre dossier et obtention des projets de statuts en 24h.</p>
                                </td>
                                <td>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Statuts juridiques sur-mesure avec des conseils et modifications illimités.</p>
                                </td>
                                <td>
                                    <p><img alt="icon check" src="/icon/big_check.svg" className={styles['checkbox']}/></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>-</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className={styles.title}>Frais de greffe et de publication légale</p>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Publication d'un annonce légale</p>
                                </td>
                                <td>
                                    <p>197 € <span className={styles.exhibitor}>HT</span></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>197 € <span className={styles.exhibitor}>HT</span></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>197 € <span className={styles.exhibitor}>HT</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Frais d'immatriculation au Greffe</p>
                                </td>
                                <td>
                                    <p>62,39 € <span className={styles.exhibitor}>HT</span></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>62,39 € <span className={styles.exhibitor}>HT</span></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>62,39 € <span className={styles.exhibitor}>HT</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Total des frais de Greffe et de publication légale</p>
                                </td>
                                <td>
                                    <p>{creationFees} € <span className={styles.exhibitor}>HT</span></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>{creationFees} € <span className={styles.exhibitor}>HT</span></p>
                                </td>
                                <td className={styles['not-pre-choice']}>
                                    <p>{creationFees} € <span className={styles.exhibitor}>HT</span></p>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td question='form.legal_pack'>
                                    <div className={`cta ${styles.cta}`} onClick={handleSubmit} choice='Premium & Réussite'>Choisir ce pack</div>
                                </td>
                                <td className={styles['not-pre-choice']} question='form.legal_pack'>
                                    <div className={`cta ${styles.cta} ${styles['not-pre-choice']}`} onClick={handleSubmit} choice='Standard'>Choisir ce pack</div>
                                </td>
                                <td className={styles['not-pre-choice']} question='form.legal_pack'>
                                    <div className={`cta ${styles.cta} ${styles['not-pre-choice']}`} onClick={handleSubmit} choice='Débutant & Seul'>Choisir ce pack</div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className="actions">
                    <Link to='/form/recap'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <div className="next" style={{display: 'none'}}>Continuer</div>
                </div>
            </div>         
        </div>
    )
}

export default LegalPack;