import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './bank.module.css';
import dataLayerPush from '../../../utils/data-layer';

function Bank() {
    const { handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button, .cta, .later, .next');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            handleClickForDataLayer();
            navigate('/form/accounting');
        }
    }

    const handleClickForDataLayer = () => {
        dataLayerPush({
        event: 'Clickbutton',
        eventLabel: 'Step 11',
        });
    };

    return (
        <div className="self-step main-18">
            <div className="global">
                <div className="questions">Souhaitez-vous ouvrir gratuitement le compte bancaire de votre entreprise chez Shine ?</div>
                <div className="description"><span className={styles.bold}>Il est obligatoire d'ouvrir un compte bancaire dédié à votre entreprise.</span> Nous vous mettons en relation avec le meilleur partenaire du marché.</div>
                <div className="answers" question="form.bank">
                    <div className="multiform" question="form.bank">
                        <div className={`big-button ${styles.button}`} choice="true" onClick={handleSubmit}>
                            <div className="reinsurance important">Choisi par 8 personnes sur 10</div>
                            <div className="emoji"><img alt="Logo Shine" src='/icon/shine.png' style={{width: '40px'}}/></div>
                            <div className="affirmation">Shine</div>
                            <div className="description"></div>
                            <div className="subtitle">Filiale de la <img alt="Logo Société Générale" src="/icon/soge.png" style={{width: '60px'}}/></div>
                        </div>
                    </div>
                    <div className={`cta ${styles.cta}`} choice='true' onClick={handleSubmit}>Choisir ce compte bancaire gratuit</div>
                    <div className="later" choice='false' onClick={handleSubmit}>Je ne souhaite pas bénéficier de ces avantages</div>
                </div>
                <div className="main-details">
                    <div className="details">
                        🌟 Comptastar a négocié pour vous ce compte bancaire Shine gratuit pendant 1 an.
                        Même si vous avez déjà un compte, celui-ci pourra nous permettre de gérer au mieux votre comptabilité.
                        Un de leurs conseillers vous appellera dans les jours à venir.
                    </div>
                </div>
                <div className="actions">
                    <Link to='/form/domiciliation'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link onClick={handleClickForDataLayer} to='/form/accounting'><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}

export default Bank;