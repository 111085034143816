import React, { useContext, useEffect } from "react";
import { UserContext } from "../../../App";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import styles from './success.module.css';
import next_step from './next_step.module.css';
import dataLayerPush from '../../../utils/data-layer';

function Success() {
    const { company } = useContext(UserContext);
    const { payment_statut, transaction_id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const pid = searchParams.get('pid');
    const installement_count = searchParams.get('installement_count');
    const payment_solution = searchParams.get('payment_solution');

    useEffect(() => {
        async function fetchData() {
            if(company.form.transaction_id) {
                // Vérification du positionnement de la transaction, et déplacement si nécessaire
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                
                const raw = JSON.stringify({
                  "deal_stage_obligation": [
                    "267376317",
                    "605682928",
                    "605670898",
                    "605699790",
                    "605670899",
                    "605670900",
                    "605670901",
                    "267376318",
                    "267376319",
                    "267376320",
                    "267376321",
                    "267376322",
                    "267376114"
                  ],
                  "transaction_id": company.form.transaction_id,
                  "new_deal_stage": "267376323"
                });
                
                const requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };
                
                try {
                  const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/hubspot/deals/update/new_stage`, requestOptions);
                  const result = await response.text();
                  console.log(result)

                  const myHeadersOptions = new Headers();
                  myHeadersOptions.append("Content-Type", "application/json");
          
                  const fetchJsonRaw = JSON.stringify({
                      "transaction_id": company.form.transaction_id
                  });
          
                  const fetchJsonOptions = {
                  method: "POST",
                  headers: myHeadersOptions,
                  body: fetchJsonRaw,
                  redirect: "follow"
                  };
          
                  try {
                      const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/firebase/fetch_json`, fetchJsonOptions);
                      const result = await response.json();
                      console.log(result)
                      const myHeadersNotes = new Headers();
                      myHeadersNotes.append("Content-Type", "application/json");
      
                      const notesRaw = JSON.stringify({
                          "deal_id": company.form.transaction_id,
                          "content": result.resultat[0]
                      });
      
                      const notesOptions = {
                          method: "POST",
                          headers: myHeadersNotes,
                          body: notesRaw,
                          redirect: "follow"
                      };
      
                      try {
                          const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/hubspot/notes/create`, notesOptions);
                          const result = await response.text();
                          console.log(result)
                      } catch (error) {
                          console.error(error);
                      };
                      console.log("Form", result.resultat[0]);
                      console.log("Payment", result.resultat[1]);
                      console.log("Contract", result.resultat[2]);
                  } catch (error) {
                      console.error(error);
                  };
      
                } catch (error) {
                  console.error(error);
                };
            }
        }
        fetchData();
        dataLayerPush({
            event: 'complete_payment'
        });
    }, [company.form.transaction_id]);
    
    useEffect(() => {
        if(!company.form.transaction_id || !company.contract.is_signed || !company.payment || !company.payment.payment_method) { 
            navigate('/form/legal-statuts'); // Redirection
        }
    }, [company.form.transaction_id, company.contract.is_signed, company.payment?.payment_method]);

    return (
        <div className="self-step main-24">
            <div className="global">
                <div className="questions">Commande validée</div>
                <div className={`${styles.subtitle} ${styles.bold}`}>
                    {company.form.dealname !== '' && <span><span className={styles.legal_name}>{company.form.dealname}</span> est </span>}{company.form.dealname === '' && <span>Votre enteprise est </span>}désormais client Comptastar ! 🎉
                </div>
                <div className={styles.subtitle}>
                    Important : Vous allez recevoir d'ici quelques minutes un formulaire par mail à remplir. Votre facture vous sera envoyée prochainement.
                </div>
                <div className={next_step.main}>
                    <div className={`${next_step.title} ${next_step.text} ${next_step.header}`} style={{paddingTop: 0}}>Les prochaines étapes</div>
                    <div className={`${next_step.timeline} ${next_step.legal}`}>
                        <div className={next_step.step}>
                            <div className={next_step.number}>1</div>
                            <img src="/timeline/legal/img-1.png" alt="Icone d'avancement, étape 1 sur 5"/>
                            <div className={next_step.title}>Formulaire en ligne</div>
                            <div className={next_step.description}>Décrivez votre projet</div>
                        </div>
                        <div className={next_step.step}>
                            <div className={next_step.number}>2</div>
                            <img src="/timeline/legal/img-2.png" alt="Icone d'avancement, étape 2 sur 5"/>
                            <div className={next_step.title}>Vérification des documents</div>
                            <div className={next_step.description}>Votre juriste valide vos informations et vous contacte</div>
                        </div>
                        <div className={next_step.step}>
                            <div className={next_step.number}>3</div>
                            <img src="/timeline/legal/img-3.png" alt="Icone d'avancement, étape 3 sur 5"/>
                            <div className={next_step.title}>Validation par le greffe</div>
                            <div className={next_step.description}>Une fois validé, notre juriste vous en informe</div>
                        </div>
                        <div className={next_step.step}>
                            <div className={next_step.number}>4</div>
                            <img src="/timeline/legal/img-4.png" alt="Icone d'avancement, étape 4 sur 5"/>
                            <div className={next_step.title}>Réception du KBIS</div>
                            <div className={next_step.description}>Votre société est immatriculé, vous recevez votre KBIS</div>
                        </div>
                        <div className={next_step.step}>
                            <div className={next_step.number}>5</div>
                            <img src="/timeline/legal/img-5.png" alt="Icone d'avancement, étape 5 sur 5"/>
                            <div className={next_step.title}>Contact avec votre comptable</div>
                            <div className={next_step.description}>Votre comptable vous contacte</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Success;