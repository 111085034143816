import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { handleFormSubmit } from '../../../utils/contact-informations';
import dataLayerPush from '../../../utils/data-layer';

function SelfEmployed() {
    const { company, setCompany, handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button, .later, .next');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            if(choice === 'true') {
                setCompany({...company, form: {...company.form, upsell: {...company.form.upsell, deregistration: true}}});
            } else {
                setCompany({...company, form: {...company.form, upsell: {...company.form.upsell, deregistration: false}}});
            }
            handleChange({
                'name': question,
                'value': choice
            });
            handleClickForDataLayer();
            navigate('/form/dealname');
        }
    }

    useEffect(() => {
        if(!company.form.transaction_id) {
            const { email, firstname, lastname, phone } = company.form.contact;
            const { legal_statuts } = company.form;
            handleFormSubmit(email, firstname, lastname, phone, legal_statuts, company, setCompany, handleChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickForDataLayer = () => {
        dataLayerPush({
        event: 'Clickbutton',
        eventLabel: 'Step 5',
        });
    };
    
    return (
        <div className="self-step main-16">
            <div className="global">
                <div className="questions">Êtes-vous déclaré <span className="important">en tant qu'auto-entrepreneur</span> ?</div>
                <div className="answers" question="form.self_employed">
                    <div className="multiform" question="form.self_employed">
                        <div className="big-button" choice="true" onClick={handleSubmit}>
                            <div className="emoji">👍</div>
                            <div className="affirmation">Oui</div>
                            <div className="description"></div>
                        </div>
                        <div className="big-button" choice="false" onClick={handleSubmit}>
                            <div className="emoji">👎</div>
                            <div className="affirmation">Non</div>
                            <div className="description"></div>
                        </div>
                    </div>
                    <div className="later" choice='false' onClick={handleSubmit}>Je souhaite répondre plus tard.</div>
                </div>
                <div className="actions">
                    <Link to='/form/contact-informations'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link to='/form/dealname' onClick={handleClickForDataLayer}><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}

export default SelfEmployed;