import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './craft-activity.module.css';
import dataLayerPush from '../../../utils/data-layer';

function CraftActivity() {
    const { handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button, .next, .later');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            handleClickForDataLayer();
            navigate('/form/domiciliation');
        }
    }


    const handleClickForDataLayer = () => {
        dataLayerPush({
        event: 'Clickbutton',
        eventLabel: 'Step 9',
        });
    };

    const details = '🌟 Pour les activités artisanales, l\'enregistrement auprès de la chambre des métiers et de l\'artisanat (CMA) est obligatoire.';
    const sub_details = 'Cet enregistrement est géré par notre équipe et coûtera 79€ HT. À noter que comme nous pouvons gérer la comptabilité de votre entreprise, tous ces frais de création vous coûteront 0€ de votre poche ! En effet, votre comptable dédié passera l\'intégralité de cette dépense comme charge de votre entreprise.';
    return (
        <div className="self-step main-15">
            <div className="global">
                <div className="questions">Votre entreprise est-elle <span className="important">artisanale</span> ?</div>
                <div className="answers" question="form.craft_activity">
                    <div className="multiform" question="form.craft_activity">
                        <div className="big-button" choice="true" onClick={handleSubmit}>
                            <div className="emoji">👍</div>
                            <div className="affirmation">Oui</div>
                            <div className="description"></div>
                        </div>
                        <div className="big-button" choice="false" onClick={handleSubmit}>
                            <div className="emoji">👎</div>
                            <div className="affirmation">Non</div>
                            <div className="description"></div>
                        </div>
                    </div>
                    <div className="later" choice="false" onClick={handleSubmit}>Je souhaite répondre plus tard.</div>
                </div>
                <div className="main-details">
                    <div className="details" style={{marginBottom: '10px'}}>{details}</div>
                    <div className="sub_details">{sub_details}</div>
                </div>
                <div className={styles['complementary-information']}>
                    <div className={`questions ${styles.questions}`}>Qu'est-ce qu'une activité artisanale ?</div>
                    <div className={styles['activity-main']}>
                        <div className={styles['activity-list']}>
                            <div className={styles.activity}>
                                <div className={styles.title}>Activités de <span className={styles.important}>services</span> artisanales</div>
                                <ul>
                                    <li>Ambulances</li>
                                    <li>Blanchisserie et pressing (sauf libre-service)</li>
                                    <li>Coiffure</li>
                                    <li>Compositions florales</li>
                                    <li>Contrôle technique</li>
                                    <li>Cordonnerie et réparation d'articles personnels et domestiques</li>
                                    <li>Déménagement</li>
                                    <li>Embaumement, soins mortuaires</li>
                                    <li>Entretien et réparation de machines de bureau et de matériel informatique</li>
                                    <li>Etalage, décoration</li>
                                    <li>Finition et restauration de meubles, dorure, encadrement</li>
                                    <li>Maréchalerie</li>
                                    <li>Pose d'affiches, travaux à façon, conditionnement à façon</li>
                                    <li>Ramonage, nettoyage, entretien de fosses septiques et désinsectisation</li>
                                    <li>Réparation automobile cycles et motocycles</li>
                                    <li>Réparation d'objets d'art</li>
                                    <li>Spectacle de marionnettes</li>
                                    <li>Soins de beauté</li>
                                    <li>Taxis et voitures de remise</li>
                                    <li>Toilettage d'animaux de compagnie</li>
                                    <li>Travaux de photographiques</li>
                                    <li>Voiture grande remise</li>
                                </ul>
                            </div>
                            <div className={styles.activity}>
                                <div className={styles.title}>Activités <span className={styles.important}>alimentaires</span> artisanales</div>
                                <ul>
                                    <li>Boulangerie, pâtisserie, biscotterie-biscuiterie</li>
                                    <li>Conservation et transformation de fruits et légumes et autres produits alimentaires</li>
                                    <li>Conservation et transformation des produits de la mer, poissonnerie</li>
                                    <li>Fabrication de glaces, sorbets, chocolaterie ou confiserie</li>
                                    <li>Fabrication de produits laitiers</li>
                                    <li>Transformation de viande, boucherie, charcuterie ou fabrication de produits à base de viande</li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles['activity-list']}>
                            <div className={styles.activity}>
                                <div className={styles.title}>Activités de <span className={styles.important}>bâtiment</span> artisanales</div>
                                <ul>
                                    <li>Aménagement, agencement et finition</li>
                                    <li>Couverture, plomberie chauffage</li>
                                    <li>Industries extractives (tourbe, pierre, argile…)</li>
                                    <li>Maçonnerie et autres travaux de construction</li>
                                    <li>Menuiserie, serrurerie</li>
                                    <li>Orpaillage</li>
                                    <li>Préparation des sites et terrassement</li>
                                    <li>Travaux d'installation électrique et d'isolation</li>
                                    <li>Travaux sous-marins de forage</li>
                                </ul>
                            </div>
                            <div className={styles.activity}>
                                <div className={styles.title}>Activités de <span className={styles.important}>fabrication</span> artisanales</div>
                                <ul>
                                    <li>Fabrication d'articles textiles, de sport, de jeux, de jouets, d'instruments de musique</li>
                                    <li>Fabrication d'instruments médicaux, de précision, d'optique, fabrication de lunettes et de matériel photographique</li>
                                    <li>Fabrication de matériel agricole de machines et d'équipements et de matériel de transport</li>
                                    <li>Fabrication et réparation d'articles d'horlogerie, bijouterie et bijouterie fantaisie</li>
                                    <li>Fabrication et réparation de machines de bureau, de matériel informatique, de machines et appareils électriques, d'équipements de radio, de télévision et de communication</li>
                                    <li>Fabrication et réparation de meubles</li>
                                    <li>Fabrication et transformation des métaux : produits chimiques (sauf principes actifs sang et médicament) caoutchouc, matières plastiques et matériaux de construction</li>
                                    <li>Fabrication de vêtements en cuir et fourrure</li>
                                    <li>Imprimerie (sauf journaux) reliure et reproduction d'enregistrements</li>
                                    <li>Récupération</li>
                                    <li>Taxidermie</li>
                                    <li>Transformation des fibres, tissage, ennoblissement</li>
                                    <li>Transformation de matières nucléaires</li>
                                    <li>Travail du bois, du papier et du carton, du cuir, fabrication de chaussures, travail du verre et des céramiques</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="actions" question="form.craft_activity">
                    <Link to='/form/activity'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link onClick={handleClickForDataLayer} to='/form/domiciliation'><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}

export default CraftActivity;