import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../../App';
import { Link } from 'react-router-dom';
import styles from './merchant-recap.module.css';
import payment_recap from '../payment-recap/payment-recap.module.css';
import checkbox from './checkbox.module.css';
import NewTab from '../../icon/new_tab';
import dataLayerPush from '../../../utils/data-layer';

function MerchantRecap() {
    const { company, handleChange } = useContext(UserContext);
    const upsellContinuation = useRef(null);
    const legal_pack_1 = useRef(null);
    const legal_pack_2 = useRef(null);
    const legal_pack_3 = useRef(null);
    const legal_pack_4 = useRef(null);
    const legal_pack_5 = useRef(null);
    const legal_pack_6 = useRef(null);
    // const upsellBox = useRef(null);
    const promo_code = useRef(null);
    // const promo_code_validation = useRef(null);
    const total_ht = useRef(null);
    const total_tva = useRef(null);
    const total_ttc = useRef(null);
    const total_promo_code = useRef(null);
    const total_button_txt = useRef(null);
    // const total_alma = useRef(null);
    // const mediaButtonInstagram = useRef(null);
    // const mediaButtonFacebook = useRef(null);
    // const mediaButtonTiktok = useRef(null);
    // const mediaButtonTwitter = useRef(null);
    // const mediaButtonSnapchat = useRef(null);
    // const mediaButtonPinterest = useRef(null);
    // const mediaButtonLinkedin = useRef(null);
    const cgv_checkbox = useRef(null);
    const checkout_cta = useRef(null);

    const [legal_pack_price, set_legal_pack_price] = useState(0);
    const [court_fees_price, set_court_fees_price] = useState(0);
    const [accounting_price, set_accounting_price] = useState(0);
    // const [social_networks_list_txt, set_social_networks_list_txt] = useState(0);
    const [promo_code_pourcentage, set_promo_code_pourcentage] = useState(0);


    useEffect(() => {
        // const total = Object.values(company.shoppingCart).reduce((sum, item) => {return (item !== true) ? sum + item : sum;}, 0);
        const total = 0;
        total_ht.current.innerHTML = parseFloat(total.toFixed(2)).toLocaleString('fr-FR').replace('.', ',') + '€';
        total_tva.current.innerHTML = (total * 0.2).toFixed(2).replace('.', ',') + '€';
        const discount = (total*1.2*promo_code_pourcentage/100);
        if(parseInt(company.form.promo_code_pourcentage) > 0) { total_promo_code.current.innerHTML = "- " + discount.toFixed(2).replace('.', ',') + '€'};
        total_ttc.current.innerHTML = parseFloat(((total * 1.2) - discount).toFixed(2)).toLocaleString('fr-FR').replace('.', ',') + '€';
        total_button_txt.current.innerHTML = 'Payer ' + parseFloat(((total * 1.2) - discount).toFixed(2)).toLocaleString('fr-FR').replace('.', ',') + '€';
        // total_alma.current.innerHTML = (((total * 1.2) - discount)/4).toFixed(2).replace('.', ',');
    }, [company.shoppingCart, promo_code_pourcentage]); // Dépendance à shoppingCart pour recalculer à chaque changement

    // function moreUpsell(event) {
    //     event.preventDefault();
    //     const button = event.target.closest(`.${styles.more}`);
    //     if(button) {
    //         upsellContinuation.current.classList.toggle(styles.upsellShow);
    //         button.innerHTML = button.innerHTML === 'tout afficher' ? 'tout cacher' : 'tout afficher';
    //     }
    // }

    // function addMedia(event) {
    //     event.preventDefault();
    //     const button = event.target.closest(`.${styles.media}`);
    //     if(button) {
    //         const media = button.getAttribute('media');
    //         let choice = null;
    //         console.log(company.form.upsell.social_community_management[media]);
    //         if(company.form.upsell.social_community_management[media]) {
    //             choice = false;
    //         } else {
    //             choice = true;
    //         }
    //         handleChange({
    //             'name': `form.upsell.social_community_management.${media}`,
    //             'value': choice
    //         })
    //     }
    // }

    useEffect(() => {
        set_promo_code_pourcentage(company.form.promo_code_pourcentage);
        set_court_fees_price(company.shoppingCart?.court_fees);
        console.log(company.form.promo_code_value);
        const inputs = [promo_code];
        inputs.forEach(ref => {
            if (ref.current && ref.current.value.length > 0) {
                // promo_code_validation.current.classList.remove(payment_recap.unselectable);
            } else {
                // promo_code_validation.current.classList.add(payment_recap.unselectable);
            }
        });
        dataLayerPush({
            event: 'view_cart'
        });
    }, [])

    useEffect(() => {
        if(company.form.domiciliation === false && company.form.bank === false && company.form.upsell.kbis_by_post === false && company.form.upsell.register_your_trademark === false && company.form.upsell.bylaws_word === false && company.form.upsell.canvassing === false && company.form.upsell.logo === false && company.form.upsell.brand_identity === false &&  company.form.upsell.business_card === false &&  company.form.upsell.flyers === false &&  company.form.upsell.domain_name_protection === false &&  company.form.upsell.computer_scientict === false &&  company.form.upsell.mail_signature === false) {
            // upsellBox.current.style.display = 'none';
        } else {
            // upsellBox.current.style.display = 'flex';
        }

        if(company.form.legal_pack === "Premium & Réussite") {
            if(company.form.fastlane) {
                set_legal_pack_price(149);
            } else {
                set_legal_pack_price(229);
            }
            [legal_pack_1, legal_pack_2, legal_pack_3, legal_pack_4, legal_pack_5, legal_pack_6].forEach((elem) => {
                elem.current.style.display = 'block';
            })
        } else if(company.form.legal_pack === "Standard") {
            if(company.form.fastlane) {
                set_legal_pack_price(99);
            } else {
                set_legal_pack_price(169);
            }
            [legal_pack_1, legal_pack_2, legal_pack_3].forEach((elem) => {
                elem.current.style.display = 'block';
            });
            [legal_pack_4, legal_pack_5, legal_pack_6].forEach((elem) => {
                elem.current.style.display = 'none';
            })
        } else {
            set_legal_pack_price(0);
            [legal_pack_1, legal_pack_2, legal_pack_3, legal_pack_4, legal_pack_5, legal_pack_6].forEach((elem) => {
                elem.current.style.display = 'none';
            })
        }

        if(company.form.legal_statuts === "Auto-entrepreneur") {
            if(company.form.fastlane) {
                company.form.accounting.contract === 'annually' ? set_accounting_price(29) : set_accounting_price(39);
            } else {
                company.form.accounting.contract === 'annually' ? set_accounting_price(39) : set_accounting_price(39);
            }
        } else {
            if(company.form.fastlane) {
                company.form.accounting.contract === 'annually' ? set_accounting_price(49) : set_accounting_price(49);
            } else {
                company.form.accounting.contract === 'annually' ? set_accounting_price(89) : set_accounting_price(89);
            }
        }
    }, [company.form, company.form.fastlane])

    // useEffect(() => {
    //     const socialPlatforms = company.form.upsell.social_community_management;
    //     if(company.form.upsell.community_management === false || company.form.upsell.community_management === null) {
    //         set_social_networks_list_txt('');
    //     } else {
    //         let social_networks_list = [];
    //         for (const platform in socialPlatforms) {
    //             if (socialPlatforms.hasOwnProperty(platform) && socialPlatforms[platform]) { // Vérifie que la propriété appartient bien à l'objet
    //                 social_networks_list.push(platform.charAt(0).toUpperCase() + platform.slice(1));
    //             }
    //         }
    //         social_networks_list = social_networks_list.join(', ');
    //         set_social_networks_list_txt(social_networks_list);
    //     }
    //     for (const platform in socialPlatforms) {
    //         if(socialPlatforms.hasOwnProperty(platform)) {
    //             switch (platform) {
    //                 case 'instagram':
    //                     if(socialPlatforms[platform]) {
    //                         mediaButtonInstagram.current.classList.add(styles.active);
    //                     } else {
    //                         mediaButtonInstagram.current.classList.remove(styles.active)
    //                     }
    //                     break;
    //                 case 'facebook':
    //                     if(socialPlatforms[platform]) {
    //                         mediaButtonFacebook.current.classList.add(styles.active);
    //                     } else {
    //                         mediaButtonFacebook.current.classList.remove(styles.active);
    //                     }
    //                     break;
    //                 case 'tiktok':
    //                     if(socialPlatforms[platform]) {
    //                         mediaButtonTiktok.current.classList.add(styles.active);
    //                     } else {
    //                         mediaButtonTiktok.current.classList.remove(styles.active);
    //                     }
    //                     break;
    //                 case 'twitter':
    //                     if(socialPlatforms[platform]) {
    //                         mediaButtonTwitter.current.classList.add(styles.active);
    //                     } else {
    //                         mediaButtonTwitter.current.classList.remove(styles.active);
    //                     }
    //                     break;
    //                 case 'snapchat':
    //                     if(socialPlatforms[platform]) {
    //                         mediaButtonSnapchat.current.classList.add(styles.active);
    //                     } else {
    //                         mediaButtonSnapchat.current.classList.remove(styles.active);
    //                     }
    //                     break;
    //                 case 'pinterest':
    //                     if(socialPlatforms[platform]) {
    //                         mediaButtonPinterest.current.classList.add(styles.active);
    //                     } else {
    //                         mediaButtonPinterest.current.classList.remove(styles.active);
    //                     }
    //                     break;
    //                 case 'linkedin':
    //                     if(socialPlatforms[platform]) {
    //                         mediaButtonLinkedin.current.classList.add(styles.active);
    //                     } else {
    //                         mediaButtonLinkedin.current.classList.remove(styles.active);
    //                     }
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         }
    //     }
    // }, [company.form.upsell.community_management, company.form.upsell.social_community_management]);

    useEffect(() => {
        async function fetchData() {
            if(company.form.transaction_id) {
                // Vérification du positionnement de la transaction, et déplacement si nécessaire
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                
                const raw = JSON.stringify({
                  "deal_stage_obligation": [
                    "267376317",
                    "267376114"
                  ],
                  "transaction_id": company.form.transaction_id,
                  "new_deal_stage": "605682928"
                });
                
                const requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };
                
                try {
                  const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/hubspot/deals/update/new_stage`, requestOptions);
                  const result = await response.text();
                  console.log(result)
                  return result;
                } catch (error) {
                  console.error(error);
                  return error;
                };
            }
        }
        fetchData();
    }, [company.form.transaction_id]);

    function upsellModification(event) {
        event.preventDefault();
        const button = event.target.closest(`.${styles.cta}`);
        if(button) {
            const question = button.getAttribute('question');
            let choice = "false";
            if(button.getAttribute('choice') === "false" || button.getAttribute('choice') === "null") {
                choice = "true";
            } else {
                choice = "false";
            }
            handleChange({
                'name': question,
                'value': choice
            });
        }
    }

    function promoCodeValidation() {
        const promo_code_value = promo_code.current.value;
        handleChange({
            name: 'form.promo_code_value',
            value: promo_code_value
        });
        if(promo_code_value === "1234") {
            set_promo_code_pourcentage(100);
            console.log('code promo mis a jour, 100% de remise', promo_code_value, promo_code_pourcentage);
            handleChange({
                name: 'form.promo_code_pourcentage',
                value: 100
            });
        } else if(promo_code_value === "JEMELANCE") {
            set_promo_code_pourcentage(10);
            console.log('code promo mis a jour, 10% de remise', promo_code_value, promo_code_pourcentage);
            handleChange({
                name: 'form.promo_code_pourcentage',
                value: 10
            });
        } else if(promo_code_value === "COMPTASTAR") {
            set_promo_code_pourcentage(20);
            console.log('code promo mis a jour, 20% de remise', promo_code_value, promo_code_pourcentage);
            handleChange({
                name: 'form.promo_code_pourcentage',
                value: 20
            });
        } else {
            set_promo_code_pourcentage(0);
            console.log('code promo mis a jour, 0% de remise', promo_code_value, promo_code_pourcentage);
            handleChange({
                name: 'form.promo_code_pourcentage',
                value: 0
            })
        }
    }

    const cgv_checkbox_update = (event) => {
        console.log(event.target);
        if(event.target.checked) {
            console.log('oui');
            checkout_cta.current.classList.remove(payment_recap.unselectable);
        } else {
            console.log('non');
            checkout_cta.current.classList.add(payment_recap.unselectable);
        }
    };

    function handleAllChange(event) {
        handleChange(event);
        if(event.target.classList) {
            if(event.target.value.length > 0) {
                // promo_code_validation.current.classList.remove(payment_recap.unselectable);
            } else {
                // promo_code_validation.current.classList.add(payment_recap.unselectable);
            }
        }
    }

    const handleClickForDataLayer = () => {
        const total = Object.values(company.shoppingCart).reduce((sum, item) => {return (item !== true) ? sum + item : sum;}, 0);
        dataLayerPush({
            event: 'initiate_checkout',
            price: (total*1.2).toFixed(2)
        });
    };

    return (
        <div className="self-step main-22">
            <div className="global">
                <div className="questions">Récapitulatif de votre commande (1/2)</div>
                <div className={styles.subtitle}>Dernière étape ! Suite au paiement, vous accèderez aux services et serez mis en relation avec votre expert dédié.</div>
                
                <div className={styles['merchant-recap']}>
                    <div className={styles.lines}>
                        <div className={styles['line-items']}>
                            <div className={styles.title}>
                                <div className={styles.name}>Création de votre entreprise <span className={styles.legal_name}>{company.form.dealname}</span></div>
                                {/* <Link to='/form/legal-pack'><div className={styles.edit}>modifier</div></Link> */}
                            </div>
                            <div className={styles['line-item']}>
                                <div className={styles['line-information']}>
                                    <div className={styles.name}>🏅 Pack {company.form.legal_pack}</div>
                                    <div className={styles.pricing}>0€{/*{legal_pack_price}€ <span className={styles.exhibitor}>HT</span>*/}</div>
                                </div>
                                <ul>
                                    <li>Préparation de vos statuts et envoi au greffe.</li>
                                    <li ref={legal_pack_1}>Garantie anti rejet du Greffe.</li>
                                    <li ref={legal_pack_2}>Réponse à toutes vos questions par un juriste en droit des sociétés et entreprise, basé en France.</li>
                                    <li ref={legal_pack_3}>Obtention accélérée de votre KBIS et SIREN.</li>
                                    <li ref={legal_pack_4}>Accompagnement illimité par téléphone avec votre juriste dédié.</li>
                                    <li ref={legal_pack_5}>Vérification de votre dossier et obtention des projets de statuts en 24h.</li>
                                    <li ref={legal_pack_6}>Statuts juridiques sur-mesure avec des conseils et modifications illimités.</li>
                                </ul>
                                <div className={styles.payLater}>Puis dans 1 jour : {legal_pack_price}€ <span className={styles.exhibitor}>HT</span></div>
                            </div>
                            <div className={styles['line-item']}>
                                <div className={styles['line-information']}>
                                    <div className={styles.name}>🧑‍⚖️ Frais de greffe et de publication légale</div>
                                    <div className={styles.pricing}>0€{/*259,39€ <span className={styles.exhibitor}>HT</span>*/}</div>
                                </div>
                                <div className={styles.description}>Ces frais sont obligatoires et imposés par l'État Français.</div>
                                <div className={styles.payLater}>Puis dans 1 jour : {court_fees_price}€ <span className={styles.exhibitor}>HT</span></div>
                            </div>
                        </div>
                        <div className={styles['line-items']}>
                            {/* <div className={styles.title}>
                                <div className={styles.name}>Services et Avantages Compris</div>
                                <Link to='/form/accounting'><div className={styles.edit}>modifier</div></Link>
                            </div> */}
                            <div className={styles['line-item']}>
                                <div className={styles['line-information']}>    
                                    <div className={styles.name}>Gestion de votre <span className={styles.legal_name}>comptabilité</span></div>
                                    <div className={styles.pricing}>15 jours offerts</div>
                                </div>
                                <div className={styles.ldm_argument}>
                                    <ul>
                                        <li>Gestion comptable de A à Z<br />(Compte annuels, accès illimités au logiciel)</li>
                                        <li>Conseils illimités en fiscalité, juridique et social<br />(sur votre secteur d'activité)</li>
                                        <li>Comptable dédié disponible 5j/7</li>
                                    </ul>
                                    {/* <div className={styles.ldm}>
                                        <div className={`cta ${styles.cta}`}>
                                        Voir ma lettre de mission <NewTab />
                                        </div>
                                        </div> */}
                                </div>
                                <div className={styles.payLater}>Puis dans 15 jours : {accounting_price.toLocaleString('fr-FR')}€ <span className={styles.exhibitor}>HT</span> {company.form.accounting.contract === 'annually' ? '/ an' : '/ mois'}</div>
                            </div>
                            {/* <div className={styles['line-item']}>
                                <div className={styles['line-information']}>    
                                    <div className={styles.name}>Assemblée Générale</div>
                                    <div className={styles.pricing}>
                                        {company.form.accounting.contract === 'annually' ? (
                                            <div className={styles.special_pricing}>
                                                <div>Gratuit</div><span className={styles.first_year}>la première année</span>
                                            </div>
                                        ) : ( 
                                            <div>
                                                119 € <span className={styles.exhibitor}>HT</span> / an
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <ul>
                                    <li>Obligation de soumettre les comptes annuels à l'approbation de leurs associés ou de leurs actionnaires.</li>
                                    <li>Dépôt de l'AG au greffe du tribunal de commerce accompagnée des comptes annuels.</li>
                                    {company.form.accounting.contract === 'annually' ? (
                                            <li>Gratuit la première année</li>
                                        ) : ( 
                                            <li>119 € <span className={styles.exhibitor}>HT</span> / an</li>
                                        )}
                                </ul>
                            </div> */}
                        </div>
                        {/* <div className={styles['line-items']} ref={upsellBox}>
                            <div className={styles.title}>
                                <div className={styles.name}></div>
                            </div>
                            {
                                company.form.domiciliation !== false && company.form.domiciliation !== null && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Domiciliation</div>
                                            <div className={styles.pricing}>Gratuit</div>
                                        </div>
                                        <div className={styles.description}>Confidentialité & Sécurité, réduction d'impôts et adresse de prestige.</div>
                                    </div>
                                )
                            }
                            {
                                company.form.bank !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Compte bancaire Shine</div>
                                            <div className={styles.pricing}>Gratuit</div>
                                        </div>
                                        <div className={styles.description}>Nous vous mettons en relation avec le meilleur partenaire du marché.</div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.bylaws_word !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Statuts au format Word</div>
                                            <div className={styles.pricing}>19,90€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>Très pratique plutôt que de payer à nouveau les statuts si vous souhaitez les modifier.</div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.register_your_trademark !== false && company.form.upsell.register_your_trademark !== null && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Dépôt de marque et protection auprès de l'INPI</div>
                                            <div className={styles.pricing}>269€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>Nos avocats experts en propriété intellectuelle s'occupent de toutes les démarches administratives.</div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.kbis_by_post !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Recevoir le KBIS par courrier</div>
                                            <div className={styles.pricing}>9,90€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>Il est toujours important et rassurant de conserver votre KBIS en papier pour les différents événements de votre entreprise.</div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.deregistration !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Radiation de votre auto-entreprise</div>
                                            <div className={styles.pricing}>169€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}></div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.canvassing !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>STOP au démarchage abusif</div>
                                            <div className={styles.pricing}>29,90€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>Durant toute la durée de votre entreprise. Inscription sur liste rouge de votre entreprise pour limiter le démarchage (centaines de courriers physiques, mails et appels sur votre téléphone).</div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.community_management !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Community Management et animation de vos réseaux sociaux</div>
                                            <div className={styles.pricing}>30 jours offerts</div>
                                        </div>
                                        <div className={styles.description}>Aujourd'hui 92.3% des Français se rendent tous les jours sur les réseaux sociaux.<br /><br />Nos experts médias et réseaux sociaux gèrent tous les posts sur les réseaux sociaux, y répondent pour faire grandir votre communauté et rendre heureux et fiers vos clients.</div>
                                        <div className={styles.social_networks}>{social_networks_list_txt}</div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.logo !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Logo pour votre entreprise</div>
                                            <div className={styles.pricing}>149€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>Libre d'utilisation sur tous les supports (flyers, site internet, packaging...) nos designers créent le logo idéal pour votre entreprise. <br /><br /><span className="sub-detail">1 aller-retour possible.</span></div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.brand_identity !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Identité de marque & Charte graphique</div>
                                            <div className={styles.pricing}>139€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>Nos illustratrices et designeuses peuvent créer une cohérence de marque à travers plusieurs polices et écritures ainsi que des couleurs superbes pour une parfaite harmonie (couleurs primaires et secondaires).<span className="sub-detail">1 aller-retour possible.</span></div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.business_card !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Design et création de vos cartes de visites</div>
                                            <div className={styles.pricing}>99€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>Obligatoire pour garder le contact avec vos clients et fournisseurs, nos illustratrices produisent les éléments pour marquer les esprits en toute sobriété et avec élégance.<span className="sub-detail">1 aller-retour possible.</span></div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.flyers !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Design et création de vos flyers</div>
                                            <div className={styles.pricing}>99€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>Idéal pour laisser une trace de vos services et avantages dans le monde physique. Notre équipe vous conseille sur les formats le plus adaptés pour atteindre vos objectifs (conversion, notoriété ou visibilité).<span className="sub-detail">1 aller-retour possible.</span></div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.domain_name_protection !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Protection de votre nom de domaine en France (site internet)</div>
                                            <div className={styles.pricing}>199€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>Nos informaticiens se chargent de la protection sur les meilleurs serveurs. La meilleure façon d'aider les internautes à vous trouver facilement, de renforcer la crédibilité de votre marque et de donner instantanément une image professionnelle à votre site.</div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.computer_scientict !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Accompagnement d'un informaticien pour votre ordinateur et paramétrage des mails</div>
                                            <div className={styles.pricing}>399€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>Il est primordial que vos adresses mails soient bien paramétrés sur les serveurs et votre ordinateur en s'assurant des boites d'envoi et réception de votre nom de domaine. À distance, nos informaticiens vous accompagneront.</div>
                                    </div>
                                )
                            }
                            {
                                company.form.upsell.mail_signature !== false && (
                                    <div className={styles['line-item']}>
                                        <div className={styles['line-information']}>
                                            <div className={styles.name}>Création et configuration de votre signature de mail</div>
                                            <div className={styles.pricing}>99€ <span className={styles.exhibitor}>HT</span></div>
                                        </div>
                                        <div className={styles.description}>C'est l'élément que vos clients verront des milliers de fois. L'équivalent d'une carte de visite en digital, nous vous la créons et paramétrons sur votre ordinateur par nos meilleurs graphistes pour votre secteur d'activité.</div>
                                    </div>
                                )
                            }
                        </div> */}
                    </div>
                    <div className={payment_recap.complementary}>
                        <div className={payment_recap.pricing}>
                            <div className={`${payment_recap.title} ${payment_recap.title_recap_1}`}>Récapitulatif</div>
                            {/* <div className={payment_recap.promo_code}>
                                <div className={payment_recap.price}>
                                    <input className={`form-login ${payment_recap.form_login}`} placeholder='Code de réduction' ref={promo_code} id="form.promo_code_value" name="form.promo_code_value" onChange={handleAllChange} value={company.form.promo_code_value} />
                                    <div className={`${payment_recap.promo_code_validation} ${payment_recap.unselectable}`} ref={promo_code_validation} onClick={promoCodeValidation}>Valider</div>
                                </div>
                            </div> */}
                            <div className={payment_recap.ht_total}>
                                <div className={payment_recap.label}>Sous-total HT :</div>
                                <div className={payment_recap.price} ref={total_ht}></div>
                            </div>
                            <div className={payment_recap.tva_total}>
                                <div className={payment_recap.label}>TVA :<div className="question overlay">?<span className="answer"><span style={{textDecoration: 'underline'}}>Votre société pourra récupérer la TVA facturée sur les frais de création.</span></span></div></div>
                                <div className={payment_recap.price} ref={total_tva}></div>
                            </div>
                            {
                                parseInt(company.form.promo_code_pourcentage) > 0 && <div className={payment_recap.promo_total}><div className={payment_recap.label}>Remise :</div><div className={payment_recap.price} ref={total_promo_code}></div></div>
                            }
                            
                            <div className={payment_recap.total}>
                                <div className={payment_recap.label}>Total TTC :</div>
                                <div className={payment_recap.price} ref={total_ttc}></div>
                            </div>
                            <div className={payment_recap.conditions}>
                                <div className={payment_recap.description}><label className={checkbox.label}><input type='checkbox' id='cgv_checkbox' ref={cgv_checkbox} onChange={cgv_checkbox_update} /><span></span></label> <label htmlFor='cgv_checkbox'>J'accepte les <a href="https://www.comptastar.fr/conditions-generales" className={payment_recap.cgv_link} target='_blank'>conditions générales</a> et la <a href="https://www.comptastar.fr/conditions-generales" className={payment_recap.cgv_link} target='_blank'>charte de vie privée</a></label></div>
                                 {/* et la <a href="#" className={payment_recap.cgv_link} target='_blank'>lettre de mission</a> */}
                            </div>
                            <Link onClick={handleClickForDataLayer} to='/form/checkout' className={payment_recap.unselectable} ref={checkout_cta}><div className={`cta ${payment_recap.cta}`} ref={total_button_txt}>Payer 587,39€</div></Link>
                            {/* <div className={payment_recap.alma}>
                                <div className={payment_recap.label}>ou <span id="alma_pricing" ref={total_alma} style={{marginLeft: '3px'}}></span>€ avec <img alt="icon alma" src="/icon/alma.png"/><div className={payment_recap.x4}><div>x4</div></div></div>
                            </div> */}
                            <div className={payment_recap.security}><img alt="icon lock" src="/icon/lock.svg"/> Paiement sécurisé</div>
                            <div className={payment_recap.reinsurance}>
                                <div className={payment_recap.important}>0 € de votre poche</div>
                                {/* <div className={payment_recap.description}>Passez l'intégralité de cette dépense comme charge de votre entreprise grâce à votre comptable.</div> */}
                            </div>
                        </div>
                        <div className={payment_recap.contact}>
                            <div className={payment_recap.title}>Une question sur votre commande ?</div>
                            <div className={payment_recap.description}>Contactez notre équipe disponible de 9h à 19h.</div>
                            <div className={payment_recap.telephone}>
                                <div className={payment_recap.number}>
                                    <img className={`${payment_recap.icon} ${payment_recap.telephone}`} alt="icon telephone" src="/icon/telephone.svg"/> <Link to="tel:+33745898101">07 45 89 81 01</Link>
                                </div>
                                <div className={payment_recap.free}>Gratuit</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={styles["merchant-recap"]}>
                    <div className={`${styles.lines} ${styles.upsell_lines}`}>
                        <div className={styles['line-items']}>
                            <div className={styles.title}>
                                <div className={styles.name}>Pour la <span className="important">réussite de votre entreprise</span></div>
                            </div>
                            <div className={styles['upsell-items']}>
                                <div className={styles['upsell-item']}>
                                    <div className={styles.details}>
                                        <div className={styles.title}>Domiciliation</div>
                                        <div className={styles.description}>Domiciliez votre entreprise et bénéficiez de nombreux avantages.</div>
                                    </div>
                                    <div className={`cta ${styles.cta}`} question='form.domiciliation' choice={`${company.form.domiciliation}`} onClick={upsellModification}>{ company.form.domiciliation !== false && company.form.domiciliation !== null ? ( "Supprimer" ) : ( "Ajouter | Gratuit" )}</div>
                                </div>
                                <div className={styles['upsell-item']}>
                                    <div className={styles.details}>
                                        <div className={styles.title}>Compte bancaire Shine</div>
                                        <div className={styles.description}>Il est obligatoire d'ouvrir un compte bancaire dédié à votre entreprise. Nous vous mettons en relation avec le meilleur partenaire du marché.</div>
                                    </div>
                                    <div className={`cta ${styles.cta}`} question='form.bank' choice={`${company.form.bank}`} onClick={upsellModification}>{ company.form.bank !== false ? ( "Supprimer" ) : ( "Ajouter | Gratuit" )}</div>
                                </div>
                                <div className={styles['upsell-item']}>
                                    <div className={styles.details}>
                                        <div className={styles.title}>Statuts au format Word</div>
                                        <div className={styles.description}>Très pratique plutôt que de payer à nouveau les statuts si vous souhaitez les modifier.</div>
                                    </div>
                                    <div className={`cta ${styles.cta}`} question='form.upsell.bylaws_word' choice={`${company.form.upsell.bylaws_word}`} onClick={upsellModification}>{ company.form.upsell.bylaws_word !== false ? ( "Supprimer" ) : ( <>Ajouter | 19,90€  <span className={styles.exhibitor}>HT</span></> )}</div>
                                </div>
                                <div className={styles['upsell-item']}>
                                    <div className={styles.details}>
                                        <div className={styles.title}>Dépôt de marque et protection auprès de l'INPI</div>
                                        <div className={styles.description}>Nos avocats experts en propriété intellectuelle s'occupent de toutes les démarches administratives.</div>
                                    </div>
                                    <div className={`cta ${styles.cta}`} question='form.upsell.register_your_trademark' choice={`${company.form.upsell.register_your_trademark}`} onClick={upsellModification}>{ (company.form.upsell.register_your_trademark !== false && company.form.upsell.register_your_trademark !== null) ? ( "Supprimer" ) : ( <>Ajouter | 269€  <span className={'${styles.exhibitor}'}>HT</span></> )}</div>
                                </div>
                                <div className={styles['upsell-item']}>
                                    <div className={styles.details}>
                                        <div className={styles.title}>Recevoir le KBIS par courrier</div>
                                        <div className={styles.description}>Il est toujours important et rassurant de conserver votre KBIS en papier pour les différents événements de votre entreprise.</div>
                                    </div>
                                    <div className={`cta ${styles.cta}`} question='form.upsell.kbis_by_post' choice={`${company.form.upsell.kbis_by_post}`} onClick={upsellModification}>{ company.form.upsell.kbis_by_post !== false ? ( "Supprimer" ) : ( <>Ajouter | 9,90€  <span className={'${styles.exhibitor}'}>HT</span></> )}</div>
                                </div>
                                <div className={styles['upsell-item']}>
                                    <div className={styles.details}>
                                        <div className={styles.title}>Radiation de votre auto-entreprise</div>
                                        <div className={styles.description}></div>
                                    </div>
                                    <div className={`cta ${styles.cta}`} question='form.upsell.deregistration' choice={`${company.form.upsell.deregistration}`} onClick={upsellModification}>{ company.form.upsell.deregistration !== false ? ( "Supprimer" ) : ( <>Ajouter | 169€  <span className={'${styles.exhibitor}'}>HT</span></> )}</div>
                                </div>
                            </div>
                            <div className={styles.more} onClick={moreUpsell}>tout afficher</div>
                            <div className={styles['upsell-continuation']} ref={upsellContinuation} active="false">
                                <div className={styles['upsell-items']}>
                                    <div className={styles['upsell-item-global']}>
                                        <div className={styles['upsell-item']}>
                                            <div className={styles.details}>
                                                <div className={styles.title}>STOP au démarchage abusif</div>
                                                <div className={styles.description}>Durant toute la durée de votre entreprise. Inscription sur liste rouge de votre entreprise pour limiter le démarchage (centaines de courriers physiques, mails et appels sur votre téléphone)</div>
                                            </div>
                                            <div className={`cta ${styles.cta}`} question='form.upsell.canvassing' choice={`${company.form.upsell.canvassing}`} onClick={upsellModification}>{ company.form.upsell.canvassing !== false ? ( "Supprimer" ) : ( <>Ajouter | 29,90€  <span className={'${styles.exhibitor}'}>HT</span></> )}</div>
                                        </div>
                                        <div className={styles['upsell-item']} style={{flexDirection: 'column'}}>
                                            <div className={`${styles['item-content']} ${styles['community_management']}`}>
                                                <div className={styles.details}>
                                                    <div className={styles.title}>Community Management et animation de vos réseaux sociaux</div>
                                                    <div className={styles.description}>Aujourd'hui 92.3% des Français se rendent tous les jours sur les réseaux sociaux.<br /><br />Nos experts médias et réseaux sociaux gèrent tous les posts sur les réseaux sociaux, y répondent pour faire grandir votre communauté et rendre heureux et fiers vos clients.</div>
                                                </div>
                                                <div className={styles.cta_global}>
                                                    <div className={`cta ${styles.cta} ${styles.community_management_cta}`} question='form.upsell.community_management' choice={`${company.form.upsell.community_management}`} onClick={upsellModification}>{ company.form.upsell.community_management !== false ? ( "Supprimer" ) : ( `Ajouter | 30j offerts` )}</div>
                                                    <div className={styles.description}>puis 990€/mois/réseau social</div>
                                                </div>
                                            </div>
                                            <div className={styles['item-supplemant']}>
                                                <div className={styles.media} onClick={addMedia} ref={mediaButtonInstagram} media='instagram'>
                                                    <div className={styles.label}>Instagram</div>
                                                </div>
                                                <div className={styles.media} onClick={addMedia} ref={mediaButtonFacebook} media='facebook'>
                                                    <div className={styles.label}>Facebook</div>
                                                </div>
                                                <div className={styles.media} onClick={addMedia} ref={mediaButtonTiktok} media='tiktok'>
                                                    <div className={styles.label}>Tiktok</div>
                                                </div>
                                                <div className={styles.media} onClick={addMedia} ref={mediaButtonTwitter} media='twitter'>
                                                    <div className={styles.label}>Twitter | X</div>
                                                </div>
                                                <div className={styles.media} onClick={addMedia} ref={mediaButtonSnapchat} media='snapchat'>
                                                    <div className={styles.label}>Snapchat</div>
                                                </div>
                                                <div className={styles.media} onClick={addMedia} ref={mediaButtonPinterest} media='pinterest'>
                                                    <div className={styles.label}>Pinterest</div>
                                                </div>
                                                <div className={styles.media} onClick={addMedia} ref={mediaButtonLinkedin} media='linkedin'>
                                                    <div className={styles.label}>Linkedin</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles['upsell-item']}>
                                            <div className={styles.details}>
                                                <div className={styles.title}>Logo pour votre entreprise</div>
                                                <div className={styles.description}>Libre d'utilisation sur tous les supports (flyers, site internet, packaging...) nos designers créent le logo idéal pour votre entreprise. <br /><br /><span className="sub-detail">1 aller-retour possible.</span></div>
                                            </div>
                                            <div className={`cta ${styles.cta}`} question='form.upsell.logo' choice={`${company.form.upsell.logo}`} onClick={upsellModification}>{ company.form.upsell.logo !== false ? ( "Supprimer" ) : ( <>Ajouter | 149€  <span className={'${styles.exhibitor}'}>HT</span></> )}</div>
                                        </div>
                                        <div className={styles['upsell-item']}>
                                            <div className={styles.details}>
                                                <div className={styles.title}>Identité de marque & Charte graphique</div>
                                                <div className={styles.description}>Nos illustratrices et designeuses peuvent créer une cohérence de marque à travers plusieurs polices et écritures ainsi que des couleurs superbes pour une parfaite harmonie (couleurs primaires et secondaires).<span className="sub-detail">1 aller-retour possible.</span></div>
                                            </div>
                                            <div className={`cta ${styles.cta}`} question='form.upsell.brand_identity' choice={`${company.form.upsell.brand_identity}`} onClick={upsellModification}>{ company.form.upsell.brand_identity !== false ? ( "Supprimer" ) : ( <>Ajouter | 139€  <span className={'${styles.exhibitor}'}>HT</span></> )}</div>
                                        </div>
                                        <div className={styles['upsell-item']}>
                                            <div className={styles.details}>
                                                <div className={styles.title}>Design et création de vos cartes de visites</div>
                                                <div className={styles.description}>Obligatoire pour garder le contact avec vos clients et fournisseurs, nos illustratrices produisent les éléments pour marquer les esprits en toute sobriété et avec élégance.<span className="sub-detail">1 aller-retour possible.</span></div>
                                            </div>
                                            <div className={`cta ${styles.cta}`} question='form.upsell.business_card' choice={`${company.form.upsell.business_card}`} onClick={upsellModification}>{ company.form.upsell.business_card !== false ? ( "Supprimer" ) : ( <>Ajouter | 99€  <span className={'${styles.exhibitor}'}>HT</span></> )}</div>
                                        </div>
                                        <div className={styles['upsell-item']}>
                                            <div className={styles.details}>
                                                <div className={styles.title}>Design et création de vos flyers</div>
                                                <div className={styles.description}>Idéal pour laisser une trace de vos services et avantages dans le monde physique. Notre équipe vous conseille sur les formats le plus adaptés pour atteindre vos objectifs (conversion, notoriété ou visibilité).<span className="sub-detail">1 aller-retour possible.</span></div>
                                            </div>
                                            <div className={`cta ${styles.cta}`} question='form.upsell.flyers' choice={`${company.form.upsell.flyers}`} onClick={upsellModification}>{ company.form.upsell.flyers !== false ? ( "Supprimer" ) : ( <>Ajouter | 99€  <span className={'${styles.exhibitor}'}>HT</span></> )}</div>
                                        </div>
                                        <div className={styles['upsell-item']}>
                                            <div className={styles.details}>
                                                <div className={styles.title}>Protection de votre nom de domaine en France (site internet)</div>
                                                <div className={styles.description}>Nos informaticiens se chargent de la protection sur les meilleurs serveurs. La meilleure façon d'aider les internautes à vous trouver facilement, de renforcer la crédibilité de votre marque et de donner instantanément une image professionnelle à votre site.<span className="sub-detail">1 aller-retour possible.</span></div>
                                            </div>
                                            <div className={`cta ${styles.cta}`} question='form.upsell.domain_name_protection' choice={`${company.form.upsell.domain_name_protection}`} onClick={upsellModification}>{ company.form.upsell.domain_name_protection !== false ? ( "Supprimer" ) : ( <>Ajouter | 199€  <span className={'${styles.exhibitor}'}>HT</span>/an</> )}</div>
                                        </div>
                                        <div className={styles['upsell-item']}>
                                            <div className={styles.details}>
                                                <div className={styles.title}>Accompagnement d'un informaticien pour votre ordinateur et paramétrage des mails</div>
                                                <div className={styles.description}>Il est primordial que vos adresses mails soient bien paramétrés sur les serveurs et votre ordinateur en s'assurant des boites d'envoi et réception de votre nom de domaine. À distance, nos informaticiens vous accompagneront.</div>
                                            </div>
                                            <div className={`cta ${styles.cta}`} question='form.upsell.computer_scientict' choice={`${company.form.upsell.computer_scientict}`} onClick={upsellModification}>{ company.form.upsell.computer_scientict !== false ? ( "Supprimer" ) : ( <>Ajouter | 399€  <span className={'${styles.exhibitor}'}>HT</span></> )}</div>
                                        </div>
                                        <div className={styles['upsell-item']}>
                                            <div className={styles.details}>
                                                <div className={styles.title}>Création et configuration de votre signature de mail</div>
                                                <div className={styles.description}>C'est l'élément que vos clients verront des milliers de fois. L'équivalent d'une carte de visite en digital, nous vous la créons et paramétrons sur votre ordinateur par nos meilleurs graphistes pour votre secteur d'activité.</div>
                                            </div>
                                            <div className={`cta ${styles.cta}`} question='form.upsell.mail_signature' choice={`${company.form.upsell.mail_signature}`} onClick={upsellModification}>{ company.form.upsell.mail_signature !== false ? ( "Supprimer" ) : ( <>Ajouter | 99€  <span className={'${styles.exhibitor}'}>HT</span></> )}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="actions">
                    <Link to='/form/legal-pack'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <div className="next" style={{display: 'none'}}>Continuer</div>
                </div>
            </div>         
        </div>
    )
}

export default MerchantRecap;