import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { Link } from 'react-router-dom';
import styles from './recap.module.css';

function Recap() {
    const { company } = useContext(UserContext);
    return (
        <div className="self-step main-20">
            <div className="global">
                <div className="questions">{company.form.dealname !== '' && <span>La création de <span className={styles.legal_name}>{company.form.dealname}</span> </span>}{company.form.dealname === '' && <span>La création de votre entreprise</span>} est presque finalisée.</div>
                <div className={styles['global-summary']}>
                    <div className={`${styles.summary} ${styles.timeline}`}>
                        <div className={styles.step}>
                            <div className={styles.bullet}><div>1</div></div>
                            <div className={styles.step_description}>
                                <div className={styles.title}>Projet</div>
                                <div className={styles.description}><img alt="Icon check" src="/icon/check.svg" style={{width: '20px'}} /></div>
                            </div>
                        </div>
                        <div className={styles.step}>
                            <div className={styles.bullet}><div>2</div></div>
                            <div className={styles.step_description}>
                                <div className={styles.title}>Service</div>
                                <div className={styles.description}><img alt="Icon check" src="/icon/check.svg" style={{width: '20px'}} /></div>
                            </div>
                        </div>
                        <div className={`${styles.step} ${styles.active}`}>
                            <div className={`${styles.bullet} ${styles.special}`}><div>3</div></div>
                            <div className={`${styles.step_description} ${styles.text_description}`}>
                                <div className={styles.title}>Commande</div>
                                <div className={styles.description}>Sélectionnez votre formule d'accompagnement et finalisez votre commande.</div>
                            </div>
                        </div>
                        <div className={styles.step}>
                            <div className={`${styles.bullet} ${styles.special}`}><div>4</div></div>
                            <div className={`${styles.step_description} ${styles.text_description}`}>
                                <div className={styles.title}>Validation</div>
                                <div className={styles.description}>Nos Juristes valident votre dossier et s'occupent des démarches administratives.</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.divider}></div>
                </div>
                
                <div className="answers">
                    <Link to='/form/legal-pack'><div className="cta">Continuer</div></Link>
                </div>
                <div className="actions">
                    <Link to='/form/accounting'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <div className="next" style={{display: 'none'}}>Continuer</div>
                </div>
            </div>         
        </div>
    )
}
export default Recap;