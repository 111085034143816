import React, { useEffect, useRef, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../App";
import styles from './contract.module.css';
import dataLayerPush from '../../../utils/data-layer';

function Contract() {
    const { company, setCompany } = useContext(UserContext);
    const [ signatureProcess, setSignatureProcess ] = useState(true);
    const [ isSigned, setIsSigned ] = useState(false);
    const [ src, setSrc ] = useState('');
    const [ isLoading, setIsLoading ] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            if(company.form?.transaction_id) {
                // Vérification du positionnement de la transaction, et déplacement si nécessaire
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                
                const raw = JSON.stringify({
                  "deal_stage_obligation": [
                    "267376317",
                    "605682928",
                    "605670898",
                    "605699790",
                    "605670899",
                    "605670900",
                    "605670901",
                    "267376114"
                  ],
                  "transaction_id": company.form?.transaction_id,
                  "new_deal_stage": "267376318"
                });
                
                const requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };
                
                try {
                  const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/hubspot/deals/update/new_stage`, requestOptions);
                  const result = await response.text();
                  console.log(result);
                  return result;
                } catch (error) {
                  console.error(error);
                  return error;
                };
            }
        }
        fetchData();
    }, [company.form?.transaction_id]);

    useEffect(() => {
        if(!company.form?.transaction_id || !company.payment || !company.payment?.payment_method) { 
            navigate('/form/legal-statuts'); // Redirection
        }
    }, [company.form?.transaction_id, company.payment?.payment_method]);


    useEffect(() => {
        const fetchData = async () => {
            if (company.contract?.id && company.contract?.participant_id) {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const raw = JSON.stringify({
                    "contract_id": company.contract?.id,
                    "participant_id": company.contract?.participant_id
                });

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow"
                };

                try {
                    const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/oneflow/accessLinkGenerator`, requestOptions);
                    const result = await response.json();
                    console.log(result);
                    const access_link_updated = result.accessLink.access_link;
                    // const access_link_updated = result.accessLink.access_link.replace('contracts', 'documents');
                    setSrc(access_link_updated);
                    setIsLoading(false);
                } catch (error) {
                    console.error(error);
                }
            } else {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const raw = JSON.stringify({
                    "transaction_id": company.form.transaction_id,
                    "source": "self-onboarding",
                    "email": company.form.contact.email,
                    "fullName": `${company.form.contact.firstname} ${company.form.contact.lastname}`,
                    "mobile": company.form.contact.phone
                });

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow"
                };

                try {
                    const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/ldm/generator`, requestOptions);
                    const result = await response.json();
                    console.log(result);
                    const access_link_updated = result.accessLink.access_link;
                    // const access_link_updated = result.accessLink.access_link.replace('contracts', 'documents');
                    setSrc(access_link_updated);
                    setCompany({ ...company, contract: { id: result.contract.id, participant_id: result.participant.participant.id, is_signed: false } });
                    setIsLoading(false);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        if (company.form.contact.email) {
            fetchData();
        }
    }, [company.form.contact.email, company.contract.id, company.contract.participant_id, company.form.transaction_id, company.form.contact.firstname, company.form.contact.lastname, company.form.contact.phone, setCompany]);


    // useState(async () => {
    //     if(company.contract.id && company.contract.participant_id) {
    //         const myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");

    //         const raw = JSON.stringify({
    //             "contract_id": company.contract.id,
    //             "participant_id": company.contract.participant_id
    //         });

    //         const requestOptions = {
    //             method: "POST",
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: "follow"
    //         };

    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/oneflow/accessLinkGenerator`, requestOptions);
    //             const result = await response.json();
    //             console.log(result);
    //             setSrc(result.accessLink.access_link);
    //             setIsLoading(false);
    //         } catch (error) { 
    //             console.error(error);
    //         };
    //     } else {
    //         const myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");

    //         const raw = JSON.stringify({
    //             "transaction_id": company.form.transaction_id,
    //             "source": "self-onboarding",
    //             "email": company.form.contact.email,
    //             "fullName": `${company.form.contact.firstname} ${company.form.contact.lastname}`,
    //             "mobile": company.form.contact.phone
    //         });

    //         const requestOptions = {
    //             method: "POST",
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: "follow"
    //         };

    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/ldm/generator`, requestOptions);
    //             const result = await response.json();
    //             console.log(result);
    //             setSrc(result.accessLink.access_link);
    //             setCompany({ ...company, contract: { id: result.contract.id, participant_id: result.participant.participant.id, is_signed: false } });
    //             setIsLoading(false);
    //         } catch (error) {
    //             console.error(error);
    //         };

    //     }
    // });

    useEffect(() => {
        dataLayerPush({
            event: 'view_form'
        });
    }, [])

    return (
        <div className="self-step main-23">
            <div className="global">
                {
                    isLoading && (
                        <div className={styles.loader}>
                            <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_l9bcfk19.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop  autoplay></lottie-player>
                            <div className={styles.loaderText}>Merci de patienter quelques secondes. Votre contrat est en cours d'édition.</div>
                        </div>
                    )
                }
                {
                    signatureProcess && (
                        <>
                        <div className="questions">Signature de votre contrat</div>
                        <div className={styles.subtitle}>
                            Une fois cette lettre de mission signé, vous serez officiellement client Comptastar.
                        </div>
                        {
                            src !== "" && (
                                <div className={`${styles['merchant-recap']} ${styles.iframeMain}`}>
                                    <div className={`${styles.content} ${styles.mandateBox} ${styles.contractBox}`}>
                                        <iframe 
                                            src={src} 
                                            title="Contrat"
                                            width="100%"
                                            height="auto"
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </>
                    )
                }
                {
                    !signatureProcess && isSigned && (
                        <>
                        <div className="questions">Merci pour votre signature</div>
                        <div className={styles.subtitle}>
                            Votre contrat a été signé avec succès.
                        </div>
                    </>
                    )
                }
            </div>
        </div>
    )
}

export default Contract;