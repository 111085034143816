import React, { useContext, useEffect, useRef } from 'react';
import { UserContext } from '../../../App';
import { Link, useNavigate } from 'react-router-dom';
import styles from './activity.module.css';
import dataLayerPush from '../../../utils/data-layer';

function Activity() {
    const { company, handleChange } = useContext(UserContext);
    const other_company_activity_input = useRef(null);
    const other_company_activity_button = useRef(null);
    let navigate = useNavigate();
    function handleSubmit(event, other) {
        if(other === undefined) {
            other_company_activity_input.current.style.display = 'none';
            other_company_activity_button.current.style.marginBottom = '0';
        } else {
            document.querySelector(`[choice="${company.form.activity}"`).classList.remove('pre-choice')
        }
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button, .later');
        const choice = button_choice.getAttribute('choice');
        const parent = button_choice.parentElement;
        const question = parent.getAttribute('question');
        console.log(choice);
        console.log(question);
        handleChange({
            'name': question,
            'value': choice
        });
        if(other === undefined) {
            handleClickForDataLayer();
            navigate('/form/craft-activity');
        }
    }
    function handleAllChange(event) {
        handleChange(event);
    };
    function otherButtonClick(event) {
        event.preventDefault();
        const button = event.target.closest('.big-button');
        if(button) {
            handleSubmit(event, true);
            other_company_activity_input.current.style.display = 'block';
            button.style.marginBottom = '56px';
        }
    }
    useEffect(() => {
        if(company.form.activity === "Autres") {
            other_company_activity_input.current.style.display = 'block';
            other_company_activity_button.current.style.marginBottom = '56px';
        }
    }, [company.form.activity])

    const handleClickForDataLayer = () => {
        dataLayerPush({
        event: 'Clickbutton',
        eventLabel: 'Step 8',
        });
    };

    return (
        <div className="self-step main-14">
            <div className="global">
                <div className="questions">Quelle sera <span className="important">l'activité</span> de votre entreprise ?</div>
                <div className="answers" question="form.activity">
                    <div className="multiform" question="form.activity">
                        <div className={`big-button ${styles.button}`} choice="E-commerce" onClick={handleSubmit}>
                            <div className="affirmation">E-commerce</div>
                            <div className="description"></div>
                        </div>
                        <div className={`big-button ${styles.button}`} choice="Import | Export" onClick={handleSubmit}>
                            <div className="affirmation">Import | Export</div>
                            <div className="description"></div>
                        </div>
                        <div className={`big-button ${styles.button}`} choice="Alimentation | Restauration" onClick={handleSubmit}>
                            <div className="affirmation">Alimentation | Restauration</div>
                            <div className="description"></div>
                        </div>
                        <div className={`big-button ${styles.button}`} choice="BTP | Aménagement" onClick={handleSubmit}>
                            <div className="affirmation">BTP | Aménagement</div>
                            <div className="description"></div>
                        </div>
                        <div className={`big-button ${styles.button}`} choice="Conseil | Consultants" onClick={handleSubmit}>
                            <div className="affirmation">Conseil | Consultants</div>
                            <div className="description"></div>
                        </div>
                        <div className={`big-button ${styles.button}`} choice="Logiciel SaaS | Applications" onClick={handleSubmit}>
                            <div className="affirmation">Logiciel SaaS | Applications</div>
                            <div className="description"></div>
                        </div>
                        <div className={`big-button ${styles.button}`} choice="Transport" onClick={handleSubmit}>
                            <div className="affirmation">Transport</div>
                            <div className="description"></div>
                        </div>
                        <div className={`big-button ${styles.button}`} choice="Autres" ref={other_company_activity_button} onClick={otherButtonClick}>
                            <div className="affirmation">Autres...</div>
                            <div className="description"></div>
                            <input type="name" id="form.other_activity" name="form.other_activity" value={company.form.other_activity} className={`form-login ${styles['form-login']}`} placeholder="Saisissez votre activité ici." style={{display: 'none'}} ref={other_company_activity_input} onChange={handleAllChange}/>
                        </div>
                    </div>
                    <Link onClick={handleClickForDataLayer} to='/form/craft-activity'><div className="cta">Continuer</div></Link>
                    <div className="later" onClick={handleSubmit} choice=''>Je souhaite répondre plus tard.</div>
                </div>
                <div className="actions">
                    <Link to='/form/register-your-trademark'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <div className="next" style={{display: 'none'}}>Continuer</div>
                </div>
            </div>         
        </div>
    )
}

export default Activity;