import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';

function ActivityType() {
    const { handleChange } = useContext(UserContext);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            navigate('/tax-assistant/remunerated')
        }
    }

    return (
        <div className="self-step main-3">
            <div className="global">
                <div className="questions">Super, dans quel <span className="important">domaine d'activité</span> votre entreprise sera-t-elle ?</div>
                <div className="answers">
                    <div className="multiform" question="assistant.activity_type">
                        <div className="big-button" onClick={handleSubmit} choice="service_provision">
                            <div className="emoji">🤝</div>
                            <div className="affirmation">Prestations de services</div>
                            <div className="description"></div>
                            <div className="subtitle">Conseil toute activité, photographe, formation, bureau d'étude, free lance...</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="service_provision_liberal">
                            <div className="emoji">🏥</div>
                            <div className="affirmation">Profession libérale</div>
                            <div className="description"></div>
                            <div className="subtitle">Kiné, infirmier, ostéo, médecin...</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="service_provision_craftsman">
                            <div className="emoji">🔧</div>
                            <div className="affirmation">Artisan</div>
                            <div className="description"></div>
                            <div className="subtitle">Plombier, BTP, électricien, coiffeur, boucher...</div>
                        </div>
                        <div className="big-button" onClick={handleSubmit} choice="sales_of_goods">
                            <div className="emoji">💼</div>
                            <div className="affirmation">Négoce</div>
                            <div className="description"></div>
                            <div className="subtitle">Achats et ventes de marchandises, prêt-à-porter, épicerie, e-commerce, import export...</div>
                        </div>
                    </div>
                </div>
                <div className='main-details'>
                    <div className="details">
                        🌟 Ce choix-là n'est pas déterminant mais pourrait vous être utile pour l'imposition de votre entreprise
                    </div>
                </div>
                <div className="actions">
                    <Link to='/tax-assistant/associates'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link to='/tax-assistant/remunerated'><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}
export default ActivityType;