import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../../App';
import { Link } from 'react-router-dom';
import styles from './result.module.css';
import dataLayerPush from '../../../utils/data-layer';

function Result() {
    const { company, handleChange } = useContext(UserContext);
    const ca_estimation_ref = useRef(null);
    const [legalState, setLegalState] = useState('');
    const [redirection, setRedirection] = useState();
    const [redirectionButton, setRedirectionButton] = useState();
    
    function handleAllChange(event) {
        handleChange(event)
    }

    useEffect(() => {
        if(ca_estimation_ref.current) {
            if(company.assistant.activity_type === "service_provision" || company.assistant.activity_type === "service_provision_craftsman" || company.assistant.activity_type === "service_provision_liberal") {
                if(company.assistant.ca_estimation !== "- de 77 700€" && company.assistant.ca_estimation !== "+ de 77 700€") {
                    ca_estimation_ref.current.value = '- de 77 700€';
                    company.assistant.ca_estimation = '- de 77 700€';
                }
                ca_estimation_ref.current.querySelector('option[value="- de 180 000€"]').hidden = true;
                ca_estimation_ref.current.querySelector('option[value="+ de 180 000€"]').hidden = true;
                ca_estimation_ref.current.querySelector('option[value="- de 77 700€"]').hidden = false;
                ca_estimation_ref.current.querySelector('option[value="+ de 77 700€"]').hidden = false;
            } else {
                if(company.assistant.ca_estimation !== "- de 180 000€" && company.assistant.ca_estimation !== "+ de 180 000€") {
                    ca_estimation_ref.current.value = '- de 180 000€';
                    company.assistant.ca_estimation = '- de 180 000€';
                }
                ca_estimation_ref.current.querySelector('option[value="- de 180 000€"]').hidden = false;
                ca_estimation_ref.current.querySelector('option[value="+ de 180 000€"]').hidden = false;
                ca_estimation_ref.current.querySelector('option[value="- de 77 700€"]').hidden = true;
                ca_estimation_ref.current.querySelector('option[value="+ de 77 700€"]').hidden = true;
            }
        }
        const form = company.assistant;
        if (form.solo_associate === true) {
            if (form.activity_type === 'service_provision' || form.activity_type === 'service_provision_craftsman' || form.activity_type === 'service_provision_liberal') {
                if (form.remunerated === 'pay_slip' || form.remunerated === 'dividends') {
                    if (form.ca_estimation === '- de 77 700€') {
                        if (form.charges === '+ de 34%') {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SASU' : 'SASU');// IS | IR ou IS
                        } else if (form.charges === '- de 34%') {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SASU' : 'SASU');// IS | IR ou IS
                        } else {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SASU' : 'SASU');// IS | IR ou IS
                        }
                    } else if (form.ca_estimation === '+ de 77 700€') {
                        setLegalState(form.remunerated === 'pay_slip' ? 'SASU' : 'SASU');// IS | IR ou IS
                    } else {
                        setLegalState(form.remunerated === 'pay_slip' ? 'SASU' : 'SASU');// IS | IR ou IS
                    }
                } else {
                    if (form.ca_estimation === '- de 77 700€') {
                        if (form.charges === '+ de 34%') {
                            setLegalState(form.remunerated === 'complementary_salaried_employment' ? 'SASU' : 'EI - EURL');// IS
                        } else if (form.charges === '- de 34%') {
                            setLegalState('AE');
                        }
                    } else if (form.ca_estimation === '+ de 77 700€') {
                        setLegalState(form.remunerated === 'complementary_salaried_employment' ? 'SASU' : 'EI - EURL - SASU');// IS
                    } else {
                        setLegalState(form.remunerated === 'complementary_salaried_employment' ? 'SASU' : 'EI - EURL - SASU');// IS

                    }
                }
            } else {
                if (form.remunerated === 'pay_slip' || form.remunerated === 'dividends') {
                    if (form.ca_estimation === '- de 180 000€') {
                        if (form.charges === '+ de 34%') {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SASU' : 'SASU');// IS | IR ou IS
                        } else if (form.charges === '- de 34%') {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SASU' : 'SASU');// IS | IR ou IS
                        } else {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SASU' : 'SASU');// IS | IR ou IS
                        }
                    } else if (form.ca_estimation === '+ de 180 000€') {
                        setLegalState(form.remunerated === 'pay_slip' ? 'SASU' : 'SASU');// IS | IR ou IS
                    } else {
                        setLegalState(form.remunerated === 'pay_slip' ? 'SASU' : 'SASU');// IS | IR ou IS
                    }
                } else {
                    if (form.ca_estimation === '- de 180 000€') {
                        if (form.charges === '+ de 34%') {
                            setLegalState(form.remunerated === 'complementary_salaried_employment' ? 'SASU' : 'EI - EURL');// IS
                        } else if (form.charges === '- de 34%') {
                            setLegalState('AE');
                        }
                    } else if (form.ca_estimation === '+ de 180 000€') {
                        setLegalState(form.remunerated === 'complementary_salaried_employment' ? 'SASU' : 'EI - EURL');
                    }
                }
            }
        } else {
            if (form.activity_type === 'service_provision' || form.activity_type === 'service_provision_craftsman' || form.activity_type === 'service_provision_liberal') {
                if (form.remunerated === 'pay_slip' || form.remunerated === 'dividends') {
                    if (form.ca_estimation === '- de 77 700€') {
                        if (form.charges === '+ de 34%') {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SAS' : 'SAS');// IS
                        } else if (form.charges === '- de 34%') {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SAS' : 'SAS');// IS
                        } else {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SAS' : 'SAS');// IS
                        }
                    } else if (form.ca_estimation === '+ de 77 700€') {
                        setLegalState(form.remunerated === 'pay_slip' ? 'SAS' : 'SAS');// IS
                    } else {
                        setLegalState(form.remunerated === 'pay_slip' ? 'SAS' : 'SAS');// IS
                    }
                } else {
                    if (form.ca_estimation === '- de 77 700€') {
                        if (form.charges === '+ de 34%') {
                            setLegalState(form.remunerated === 'complementary_salaried_employment' ? 'SAS' : 'SARL');// IS | IS
                        } else if (form.charges === '- de 34%') {
                            setLegalState(form.remunerated === 'complementary_salaried_employment' ? 'SAS' : 'SARL');// IS | IS
                        }
                    } else if(form.ca_estimation === '+ de 77 700€') {
                        setLegalState(form.remunerated === 'complementary_salaried_employment' ? 'SAS' : 'SARL');// IS | IS
                    }
                }
            } else {
                if(form.remunerated === 'pay_slip' || form.remunerated === 'dividends') {
                    if(form.ca_estimation === '- de 180 000€') {
                        if(form.charges === '+ de 34%') {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SAS' : 'SAS');// IS
                        } else if(form.charges === '- de 34%') {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SAS' : 'SAS');// IS
                        } else {
                            setLegalState(form.remunerated === 'pay_slip' ? 'SAS' : 'SAS');// IS
                        }
                    } else if(form.ca_estimation === '+ de 180 000€') {
                        setLegalState(form.remunerated === 'pay_slip' ? 'SAS' : 'SAS');// IS
                    } else {
                        setLegalState(form.remunerated === 'pay_slip' ? 'SAS' : 'SAS');// IS
                    }
                } else {
                    if(form.ca_estimation === '- de 180 000€') {
                        setLegalState(form.remunerated === 'complementary_salaried_employment' ? 'SAS' : 'SARL');// IS
                    } else if(form.ca_estimation === '+ de 180 000€') {
                        setLegalState(form.remunerated === 'complementary_salaried_employment' ? 'SAS' : 'SARL');// IS
                    }
                }
            }
        }
    }, [company.assistant]);

    useEffect(() => {
        if(company.assistant.solo_associate === "") {
            handleChange({
                'name': 'assistant.solo_associate',
                'value': 'true'
            });
        }
    })

    useEffect(() => {
        console.log(`legalState updated ${legalState}`);
        if(!legalState.includes('-')) {
            console.log('choix unique');
            handleChange({
                'name': 'form.legal_statuts',
                'value': legalState
            });
            setRedirection(`/form/legal-statuts/${legalState}`);
            setRedirectionButton(`Créer ma ${legalState}`);
        } else {
            console.log('choix multiple');
            setRedirection('/form/legal-statuts');
            setRedirectionButton(`Créer ma société`);
        }
    }, [legalState])

    const handleClickForDataLayer = () => {
        dataLayerPush({
        event: 'Clickbutton',
        eventLabel: 'Step 3bis',
        });
    };

    return (
        <div className={`self-step ${styles["self-step"]} ${styles['main-7']}`}>
            <div className={`global ${styles['global']}`}>
                <div className="questions">Voilà le <span className="important">statut</span> qui vous correspond le mieux, suite à vos réponses.</div>
                <div className={`answers ${styles.answers}`}>
                    <div className={styles.list}>
                        <div className={styles.answer}>
                            <label className={styles.label} htmlFor="assistant.solo_associate">Je veux crééer mon entreprise</label>
                            <select name="assistant.solo_associate" id="assistant.solo_associate" value={company.assistant.solo_associate} onChange={handleAllChange}>
                                <option value="true">Seul</option>
                                <option value="false">À plusieurs</option>
                            </select>
                        </div>
                        <div className={styles.answer}>
                            <label className={styles.label} htmlFor="assistant.activity_type">Type d'activité</label>
                            <select name="assistant.activity_type" id="assistant.activity_type" value={company.assistant.activity_type} onChange={handleAllChange}>
                                <option value="service_provision">Prestations de services</option>
                                <option value="service_provision_liberal">Profession libérale</option>
                                <option value="service_provision_craftsman">Artisan</option>
                                <option value="sales_of_goods">Achat et vente de marchandises</option>
                            </select>
                        </div>
                        <div className={styles.answer}>
                            <label className={styles.label} htmlFor="assistant.remunerated">Type de rémunération</label>
                            <select name="assistant.remunerated" id="assistant.remunerated" value={company.assistant.remunerated} onChange={handleAllChange}>
                                <option value="pay_slip">Je veux avoir un bulletin de salaire</option>
                                <option value="immediate">J'ai un besoin immédiat de rémunération</option>
                                <option value="dividends">Je me verserai des dividendes</option>
                                <option value="complementary_salaried_employment">Ce sera un complément à mon activité de salarié</option>
                            </select>
                        </div>
                        <div className={styles.answer}>
                            <label className={styles.label} htmlFor="assistant.ca_estimation">Chiffre d'affaires estimé</label>
                            <select name="assistant.ca_estimation" id="assistant.ca_estimation" value={company.assistant.ca_estimation} onChange={handleAllChange} ref={ca_estimation_ref}>
                                <option value="- de 77 700€">- de 77 700€</option>
                                <option value="+ de 77 700€">+ de 77 700€</option>
                                <option value="- de 180 000€">- de 180 000€</option>
                                <option value="+ de 180 000€">+ de 180 000€</option>
                            </select>
                        </div>
                        <div className={styles.answer}>
                            <label className={styles.label} htmlFor="assistant.charges">Charges</label>
                            <select name="assistant.charges" id="assistant.charges" value={company.assistant.charges} onChange={handleAllChange}>
                                <option value="- de 34%">- de 34%</option>
                                <option value="+ de 34%">+ de 34%</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.divider}>
                        <div className={styles["line-divider"]}></div>
                    </div>
                    <div className={styles.result}>
                        <div className={styles.label}>Votre forme juridique idéale</div>
                        <div className={styles.final}>{legalState}</div>
                        <Link onClick={handleClickForDataLayer} to={redirection}><div className={styles.cta}>{redirectionButton}</div></Link>
                        <div className={styles['main-details']}>
                            <div className={styles.details}>🌟 Les prochaines étapes iront encore plus vite et</div>
                            <div className={styles.details}>nos juristes pourront créer votre société en 48h et</div>
                            <div className={styles.details}>obtenir rapidement votre KBIS. Pour rappel, la</div>
                            <div className={styles.details}>création d'entreprise est offerte alors profitez-en !</div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <Link to='/tax-assistant/charges'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link to={redirection}><div className="next" style={{ display: 'none' }}>Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}
export default Result;