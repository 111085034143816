import { Link, useParams } from "react-router-dom"
import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom'
import styles from './legal-statuts.module.css';
import dataLayerPush from '../../../utils/data-layer';

function LegalStatuts() {
    const { legal_statut } = useParams();
    const { handleChange } = useContext(UserContext);
    useEffect(() => {
        if(legal_statut) {
            navigate('/form/contact-informations');
        }
    }, [legal_statut]);
    let navigate = useNavigate();
    function handleSubmit(event) {
        event.preventDefault();
        // Faites quelque chose ici, puis naviguez
        const button_choice = event.target.closest('.big-button, .later');
        if(button_choice) {
            const choice = button_choice.getAttribute('choice');
            const parent = button_choice.parentElement;
            const question = parent.getAttribute('question');
            console.log(choice);
            console.log(question);
            handleChange({
                'name': question,
                'value': choice
            });
            handleClickForDataLayer();
            navigate('/form/contact-informations');
        }
    }

  const handleClickForDataLayer = () => {
    dataLayerPush({
      event: 'Clickbutton',
      eventLabel: 'Step 3',
    });
  };

    return (
        <div className="self-step main-8">
            <div className="global">
                <div className="questions">Choisissez <span className="important">le statut juridique</span> de votre future entreprise :</div>
                <div className="answers" question="form.legal_statuts">
                    <div className="multiform" question="form.legal_statuts">
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="SAS">
                            <div className="affirmation">SAS</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>Société flexible avec plusieurs associés.</div>
                        </div>
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="SASU">
                            <div className="affirmation">SASU</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>Société flexible avec un associé.</div>
                        </div>
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="SARL">
                            <div className="affirmation">SARL</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>Limite la responsabilité des associés à leurs apports.</div>
                        </div>
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="EURL">
                            <div className="affirmation">EURL</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>SARL avec un seul associé, patrimoine protégé.</div>
                        </div>
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="EIRL">
                            <div className="affirmation">EIRL</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>Entrepreneur individuel à responsabilité limitée.</div>
                        </div>
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="Auto-entrepreneur">
                            <div className="affirmation">Auto-entrepreneur</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>Régime fiscal simplifié avec plafonds de chiffre d'affaires.</div>
                        </div>
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="Entreprise individuelle">
                            <div className="affirmation">Entreprise individuelle</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>Activité en nom propre sans plafond de chiffre d'affaires.</div>
                        </div>
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="Association">
                            <div className="affirmation">Association</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>Organisation loi 1901 avec au moins deux personnes.</div>
                        </div>
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="Holding">
                            <div className="affirmation">Holding</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>Regroupe participations, optimise fiscalité et gestion.</div>
                        </div>
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="SCI">
                            <div className="affirmation">SCI</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>Société permettant de gérer et louer des biens immobiliers.</div>
                        </div>
                        <div className={`big-button ${styles.bigButton}`} onClick={handleSubmit} choice="LMNP">
                            <div className="affirmation">LMNP</div>
                            <div className="description"></div>
                            <div className={`subtitle ${styles.subtitle}`}>Statut pour louer un bien meublé avec une fiscalité allégée.</div>
                        </div>
                    </div>
                    <div className="later" onClick={handleSubmit} choice=''>Je souhaite répondre plus tard.</div>
                </div>
                <div className="actions">
                    <Link to='/'><div className="prev"><div className="arrow"></div>Retour</div></Link>
                    <Link to='/form/contact-informations'><div className="next">Continuer</div></Link>
                </div>
            </div>         
        </div>
    )
}

export default LegalStatuts